import React from "react";
import Datatable from "../common/datatable/datatable";
import { fetchData } from "./../../utils/fetch";
import { useState } from "react";
import { useEffect } from "react";
import { Link, Prompt } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import Breadcrumb from "./../common/breadcrumb";
import { Fragment } from "react";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";
import { InputLabel } from "@material-ui/core/";

const styles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
  textField: {
    "& .MuiInputLabel-root": {
      flex: "1 1 30%",
    },
    "& .MuiFormControl-root": {
      flex: "1 1 70%",
    },
  },
  trTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  list: {
    "& .MuiListItem-root": {
      padding: 4,
    },
    "& .MuiListItemIcon-root": {
      alignSelf: "center",
      justifyContent: "center",
    },
  },
  container: {
    "& .MuiAutocomplete-listbox": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .MuiAutocomplete-option": {
      flex: "0 0 80px",
    },
    "& .related-product .MuiSvgIcon-root": {
      position: "absolute",
      right: 4,
    },
    "& .MuiSelect-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& label": {
      margin: [[8, 0, 4, 0]],
    },
    "& input": {
      padding: 8,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: [[8, 65, 8, 8]],
      "& input.MuiAutocomplete-input": {
        padding: 0,
      },
    },
  },
}));

const MyTextField = (props) => (
  <TextField
    {...props}
    label={props.label}
    name={props.name}
    value={props.value}
    multiline={props.multiline}
    onChange={props.onChange}
    helperText={props.helperText}
    error={props.helperText}
    margin={props.margin || "none"}
    className={props.className}
    variant="outlined"
    size="small"
  />
);

const TopLabelTextField = (props) => (
  <Box ml={props.ml ? props.ml : 0} mr={props.mr ? props.mr : 0}>
    <InputLabel margin="none">{props.label}</InputLabel>
    <MyTextField {...props} label="" margin="none" />
  </Box>
);

const LeftLabelTextField = (props) => (
  <Box display="flex" className={props.className} alignItems="center">
    <InputLabel>{props.label}</InputLabel>
    <MyTextField {...props} className="" label="" />
  </Box>
);

const Variations = (props) => {
  const [state, setState] = useState({
    validator: new SimpleReactValidator(),
    description: "",
    unsaved: false,
    edit: props.match.params.id ? true : false,
  });

  useEffect(
    () => {
      fetchVariation();
    },
    [props.token]
  );

  const fetchVariation = async () => {
    try {
      const obj = {
        id: props.match.params.id,
        token: props.token,
        email: props.email,
      };
      let response = await fetchData(obj, "product/variation/get.php");
      if (response.status === "fail") throw response.message;
      setState((prev) => ({
        ...prev,
        description: response.description,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const saveVariation = async () => {
    try {
      const obj = {
        id: props.match.params.id,
        description: state.description,
        token: props.token,
        email: props.email,
      };
      let response = await fetchData(
        obj,
        "product/variation/" + (state.edit ? "edit" : "new") + ".php"
      );
      if (response.status === "fail") throw response.message;
      setState((prev) => ({
        ...prev,
        description: response.descrition,
        unsaved: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const setStateFromInput = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      unsaved: true,
    });
  };

  const classes = styles();

  return (
    <Fragment>
      <Prompt
        when={state.unsaved}
        message="Ai modificari nesalvate, sigur vrei sa parasesti pagina?"
      />
      <Breadcrumb title="Produs" list={[["/variations/", "Variatii"]]} />
      <div className={"container-fluid " + classes.container}>
        <Box display="flex">
          <Box flex="1 1 70%">
            <Box mx={1} mb={2} display="grid">
              <TopLabelTextField
                label="Dimensiune"
                className={classes.input}
                margin="none"
                helperText={state.validator.message(
                  "description",
                  state.description,
                  "required|string"
                )}
                name="name"
                value={state.description}
                onChange={setStateFromInput}
              />
            </Box>
          </Box>
          <Box flex="1 1 30%">
            <Box mx={1} mb={2}>
              <Button onClick={saveVariation} variant="outlined">
                Salveaza
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Variations);
