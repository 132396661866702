import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Box, Button, TextField, FormLabel, FormControl, FormControlLabel, RadioGroup, Radio, List, ListItem, ListItemText } from "@material-ui/core";
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
import Modal from '@material-ui/core/Modal';
import { Visibility } from '@material-ui/icons';

const useStyles = theme => ({
  pointerCursor: {
    cursor: 'pointer'
  },
  edit: {
      backgroundColor: '#4caf50',
      color: 'white',
  },
  delete: {
    backgroundColor: '#f44336',
    color: 'white',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  modal: {
    position: 'absolute',
    width: '100%',
    background: 'white',
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  [theme.breakpoints.up('md')]: {
    modal: {
      '& .modal-dialog': { 
        minWidth: 500,
      }
    },
  },
  [theme.breakpoints.down('md')]: {
    imageOption: {
      flexDirection: 'column',
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
        alignSelf: 'center',
      },
      '& > .MuiBox-root': {
        justifyContent: 'center'
      }
    }
  }
});

const mockupResults = [
    "iphone 12",
    "masti",
    "imprimanta",
    "iphone 12 pro",
    "brad",
    "casti",
    "masti medicale",
    "camera web",
    "lego",
    "craciun"
  ];

const initState = { 
  validator: new SimpleReactValidator(),
  name: "Nume categorie",
  description: "Descriere",
  upload_picture: "",
  //background: "",
  imageOption: "library"
};

export class Form extends Component {
  constructor(props) {
    super(props);
    this.edit = false;
    props.data
      ? (this.state = {
          validator: new SimpleReactValidator(),
          name: props.data.name,
          description: props.data.description,
          imageOption: props.data.image_option,
          library_picture: <img src={props.data.library_picture}/>,
          upload_picture: this.getBase64Image(props.data.upload_picture)
        }) : this.state = initState;
  }

  componentDidMount() {
    this.props.data && this.getBase64Image(this.props.data.upload_picture, "upload_picture");
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    const { show, onCancel, data, intl: {formatMessage} } = this.props;
    const classes = this.props.classes;
    return (
      <Box
        open={show}
        style={{
          visibility: show ? 'visible' : 'hidden'
        }}
        onClose={onCancel}
        className={[classes.modal]}
      >
        <Box display="flex" flexDirection="column">
          <List dense>
          { mockupResults.map((el,i) => 
             <ListItem>
              <ListItemText primary={el}>
              </ListItemText>
            </ListItem>
          ) }
          </List>          
        </Box>
      </Box>
    );
  }
}

export default compose(withStyles(useStyles))(injectIntl(Form));
