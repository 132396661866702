import shop from '../api/shop';
import * as types from '../constants/ActionTypes';
import store from '../store';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { fetchData } from './../utils/fetch';

export const setUser = (email, name) => {
	store.dispatch({
		type: types.ADD_USER,
		email: email,
		name: name
	});
};

export const setType = (auth) => {
	store.dispatch({
		type: 'ADD_TYPE',
		auth: auth
	});
};

export const setToken = (token) => {
	store.dispatch({
		type: 'ADD_TOKEN',
		token: token
	});
};

export const setMessage = (message) => {
	store.dispatch({
		type: types.ADD_MSG,
		message: message
	});
};

export const fetchProductsBegin = () => ({
	type: types.FETCH_PRODUCTS_BEGIN
});

export const receiveProducts = (products) => ({
	type: types.RECEIVE_PRODUCTS,
	products
});

export const getRelatedProducts = (productId) =>
	store.dispatch({
		type: types.RELATED_PRODUCTS,
		productId
	});

export const setCategories = (categories) =>
	store.dispatch({
		type: 'SET_CATEGORY',
		categories
	});

export const fetchSingleProduct = async (productId) => {
	try {
		let response = await fetch('https://admin.uleiuri-cosmetice.ro/api/rest/product/get.php/?id=' + productId);
		let data = await response.json();
		console.log(data);
		store.dispatch({
			type: types.FETCH_SINGLE_PRODUCT,
			item: data
		});
	} catch (error) {
		console.error(error);
	}
};

//it seems that I should probably use this as the basis for "Cart"

export const chooseShippingMethod = (shipping_method) => {
	store.dispatch({
		type: 'CHOOSE_SHIPING_METHOD',
		shipping_method
	});
};

export const chooseShippingAddress = (shipping_address) => {
	store.dispatch({
		type: 'CHOOSE_SHIPPING_ADDRESS',
		shipping_address
	});
};

export const chooseBillingDetails = (billing_details) => {
	store.dispatch({
		type: 'CHOOSE_BILLING_DETAILS',
		billing_details
	});
};

export const addToCartUnsafe = (product, qty) =>
	store.dispatch({
		type: types.ADD_TO_CART,
		product,
		qty
	});
export const changeQty = (product, qty) =>
	store.dispatch({
		type: 'CHANGE_QTY',
		product,
		qty
	});
export const decrementQty = (productId) =>
	store.dispatch({
		type: types.DECREMENT_QTY,
		productId
	});
export const removeFromCart = (product_id) =>
	store.dispatch({
		type: types.REMOVE_FROM_CART,
		product_id
	});
export const addToWishlistUnsafe = (product) =>
	store.dispatch({
		type: types.ADD_TO_WISHLIST,
		product
	});
export const removeFromWishlist = (product_id) =>
	store.dispatch({
		type: types.REMOVE_FROM_WISHLIST,
		product_id
	});

export const addToCart = (product, qty) => {
	toast.success('Item Added to Cart');
	addToCartUnsafe(product, qty);
};
export const addToCartAndRemoveWishlist = (product, qty) => {
	toast.success('Item Added to Cart');
	addToCartUnsafe(product, qty);
	removeFromWishlist(product.id);
};

export const incrementQty = (product, qty) => {
	toast.success('Item Added to Cart');
	addToCartUnsafe(product, qty);
};
export const addToWishlist = (product) => {
	toast.success('Item Added to Wishlist');
	addToWishlistUnsafe(product);
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
	toast.success('Item Added to Compare');
	addToCompareUnsafe(product);
};
export const addToCompareUnsafe = (product) => ({
	type: types.ADD_TO_COMPARE,
	product
});
export const removeFromCompare = (product_id) => ({
	type: types.REMOVE_FROM_COMPARE,
	product_id
});

// Filters
export const filterBrand = (brand) => ({
	type: types.FILTER_BRAND,
	brand
});
export const filterColor = (color) => ({
	type: types.FILTER_COLOR,
	color
});
export const filterPrice = (value) => ({
	type: types.FILTER_PRICE,
	value
});
export const filterName = (name) => ({
	type: types.FILTER_NAME,
	name
});
export const filterSort = (sort_by) => ({
	type: types.SORT_BY,
	sort_by
});

// Currency
export const changeCurrency = (symbol) => ({
	type: types.CHANGE_CURRENCY,
	symbol
});

/*
const mapStateToProps = (state) => ({
    user :  this.props.firebase.getUser()
})

const mapDispatchToProps = (dispatch) => ({
    getUser :  dispatch(getUser)
})*/

//export default productReducer;
/*export default compose(
  withFirebase,
  withReducer,
  connect(mapStateToProps, mapDispatchToProps)
)(getUser);*/
