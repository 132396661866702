import React from "react";
import Datatable from "../common/datatable/datatable";
import { fetchData } from "./../../utils/fetch";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";

const Products = (props) => {
  const [state, setState] = useState({
    products: [],
    categories: []
  });
  

  useEffect(
    () => {
      const fetchData = async () => {
        const products = await fetchAllProducts();
        const categories = await fetchCategories();
        products.length && categories.length &&
          setState((prev) => ({
            ...prev,
            categories: categories,
            products: products.map((el) => ({
              id: el.id,
              name: el.name,
              category: categories.find(_el => _el.id == el.category).name,
              pictures: JSON.parse(el.pictures) ? JSON.parse(el.pictures) : [{}],
              //variations: JSON.parse(el.variations),
            })),
          }));
      }
      fetchData();
  
    },
    
    [props.token]
  );

  const fetchCategories = async () => {
    try {
      const obj = { token: props.token, email: props.email };
      let response = await fetchData(obj, "product/category/get.php");
      if (response.status === "fail") throw response.message;
      console.log(response);
      //let parents = [...new Set(response.map(el => el.parent))];
      const categories = response
        .filter((el) => el.final === "1")
        .map((el) => ({
          ...el,
          filters: JSON.parse(el.filters),
        }));
      return categories;
    }
     catch (error) {
    console.log(error);
    return []
  }
  }

  const fetchAllProducts = async () => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
      };
      let products = await fetchData(obj, "product/get.php");
      if (products.status === "fail") throw products.message;
      return products;
    } catch (error) {
      console.log(error);
      return []
    }
  };

  return (
    <>
      <Link to={`/product/new/`}>Adauga un produs nou</Link>
      <Datatable
        //edit={edit}
        //remove={remove}
        path="product"
        myData={[...state.products]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Products);
