import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  withStyles,
  ListItem,
  ListItemText,
  useMediaQuery,
  useScrollTrigger,
  Slide,
  Drawer,
  Tooltip,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
// { setUser, setToken, setMessage, filterName, setCart, setWishlist } from '../../actions';
import { TextField } from "@material-ui/core";
import CartContainer from "../cart/containers/CartContainer";
import Form from "./headers/search/form";
import { Autocomplete } from "@material-ui/lab";
import {
  SearchOutlined,
  Close,
  Person,
  Favorite,
  ShoppingCart,
  PersonOutline,
} from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { fetchData } from "./../../utils/fetch";
import { setCategories } from "./../../actions/index";
import AccountSidebar from "../account/account-sidebar";
import { Divider } from "@material-ui/core";
//import Menu from '../pages/menu';
const mockupResults = [
  "iphone 12",
  "masti",
  "imprimanta",
  "iphone 12 pro",
  "brad",
  "casti",
  "masti medicale",
  "camera web",
  "lego",
  "craciun",
];

const CustomAutocomplete = ({
  id,
  options,
  onChange,
  onSearch,
  onClose,
  style,
}) => (
  <Autocomplete
    id={id}
    options={options}
    getOptionLabel={(option) => option}
    popupIcon={<SearchOutlined onClick={onSearch} />}
    freeSolo
    forcePopupIcon
    blurOnSelect
    style={style}
    openOnFocus
    //debug
    disablePortal
    //onClick={e => {e.preventPropagation()}}
    //onChange={(e,v)=>{console.log(e+v)}}
    closeIcon={<Close />}
    //onClose={(e,v)=>{console.log(e+v)}}
    renderInput={(params) => <TextField {...params} variant="outlined" />}
  />
);

const useStyles = makeStyles((theme) => ({
  header: {
    flex: "1 0 100%",
    // [theme.breakpoints.down(400)]: {
    //   '& .MuiButton': {
    //     padding:
    //   }
    // }
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    [theme.breakpoints.up(400)]: {
      marginRight: theme.spacing(2),
    },
  },
  link: {
    color: "#000000",
    display: "flex",
    alignItems: "center",
  },
  /*drawer: {
    '& .MuiListItemText-root a': {
      color: theme.palette.text.primary,
    },
    '& .MuiBackdrop-root': {
      background: 'transparent'
    }
  },*/
  search: {
    flex: "1 1 auto",
    "& > .MuiFormControl-root": {
      width: "100%",
      margin: "auto",
    },
    "& .MuiAutocomplete-popupIndicatorOpen": {
      transform: "none",
    },
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    // height: '100%',
    // position: 'absolute',
    // pointerEvents: 'none',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    display: "none",
    "& .MuiAutocomplete-root": {
      position: "fixed",
      left: 0,
      top: 0,
      background: "white",
      width: "100%",
      padding: theme.spacing(1),
      zIndex: 1000,
      "& .MuiAutocomplete-clearIndicator": {
        visibility: "visible",
      },
    },
    "& .MuiAutocomplete-popper": {
      left: 0,
      padding: theme.spacing(1),
      minWidth: "100%",
      position: "fixed",
      right: 0,
    },
    // '& .MuiBox-root': {
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   position: 'fixed',
    //   background: 'white',
    //   zIndex: 1000,
    // },
    /* '& .MuiAutocomplete-endAdornment': {
      position: 'relative'
    },
    '& .MuiAutocomplete-popupIndicatorOpen':{
      transform: 'none'
    },*/
    [theme.breakpoints.down("sm")]: {
      display: "inherit",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  // sectionDesktop: {
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  // },
  // sectionMobile: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  menuItem: {
    [theme.breakpoints.down(400)]: {
      padding: 0,
    },
    "&.MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiTypography-root": {
      position: "relative",
      "& a": {
        padding: 8,
        [theme.breakpoints.down(400)]: {
          padding: 4,
        },
      },
    },
    "& .MuiTooltip-tooltip": {
      background: "white",
      padding: 0,
      //maxWidth: '100%'
    },
    "& .MuiTooltip-arrow": {
      color: "white",
    },
    "& .MuiTooltip-tooltipPlacementBottom": {
      margin: "0",
      boxShadow: "1px 1px 20px 0px rgba(0,0,0,0.6)",
    },
    /*'& .MuiTooltip-popper': {
      transform: 'translate(0,60px)'
    },*/
    /*'& :not(.makeStyles-searchIcon-7) a + .MuiBox-root': {
      display: 'none',
      position: 'absolute',
      width: 'max-content',
      background: 'white',
      border: '1px solid grey',
      padding: 8,
      borderRadius: 4,
      //transform: 'translateX(-50%)',
      //left: '50%',
      right: 0,
      top: 32
    },
    '& a:hover + .MuiBox-root, a + .MuiBox-root:hover': {
      display: 'inherit'
    },*/
    [theme.breakpoints.down("mld")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiListItemText-root": {
        flex: "0 auto",
        margin: [[0, 16]],
      },
    },
    [theme.breakpoints.down("lg")]: {
      "& a > .MuiTypography-root": {
        display: "none",
      },
    },
    /*[theme.breakpoints.down('sm')]: {      
      position: 'unset',
    },*/
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    //width: 240,
    flexShrink: 0,
    zIndex: "1200 !important",
    "& .MuiBackdrop-root": {
      // background: 'transparent'
      top: 64,
    },
    "& svg + a": {
      fontSize: 12,
    },
    "& .category-title": {
      fontSize: 14,
    },
    "& .subcategory-title": {
      fontSize: 12,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  drawerPaper: {
    //width: 240,
    top: 64,
    background: "rgb(241,241,241)",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger();

  return (
    // <Slide appear={false} direction="down" in={!trigger}>
    children
    // </Slide>
  );
};

const staticCategories = [
  ["Pagina principala", "/front-page/"],
  ["Produse", "/products/"],
  ["Variatii", "/variations/"],
  ["Categorii", "/categories/"],
  ["Media", "/media/"],
  ["Comenzi", "/orders/"],
  ["Review-uri", "/reviews/"],
  ["Postari", "/posts/"],
  ["Pagini", "/pages/"],
  ["Retete", "/recipes/"]
];

const NavBar = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
    selectedCategory: -2,
    toolbarIndex: -1,
    showSearch: false,
    openCartTooltip: false,
    searchText: "",
  });
  const { formatMessage } = props.intl;
  const sm = useMediaQuery("(min-width:576px)");
  const md = useMediaQuery("(min-width:768px)");
  const lg = useMediaQuery("(min-width:992px)");
  const xl = useMediaQuery("(min-width:1200px)");
  const desktop = useMediaQuery("(min-width:900px)");

  const getElementWidth = () => {
    let width = "100%";
    sm && (width = "540px");
    md && (width = "720px");
    lg && (width = "960px");
    xl && (width = "1140px");
    return width;
  };
  useEffect(() => {
    //document.querySelector(".loader-wrapper").style = "display: none";
    //
    //fetchCategories();
  }, []);

  const closeDrawer = () => {
    setState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const openDrawer = () => {
    setState((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const toggleDrawer = () => {
    setState({
      ...state,
      open: !state.open,
    });
  };

  const id = props.match.params.id || -1;
  const fetchCategories = async () => {
    try {
      const obj = { id: id };
      let response = await fetchData(obj, "product/category/get.php");
      //  if (response.status === "fail")
      //    throw response.message;
      // console.log(response);
      setCategories(response);
    } catch (error) {
      console.log(error);
    }
  };

  const generateMenu = (array, style) => (
    <ListItem
      disableGutters
      component="div"
      className={classes.menuItem + " " + style.className}
    >
      {array.map((el, i) => (
        <Tooltip
          key={"toolkit" + el.id}
          arrow
          id="cart-tooltip"
          placement="bottom-end"
          interactive
          PopperProps={{
            disablePortal: true,
          }}
          /*onOpen={(e)=>{
            console.log(e)
            document.querySelector('#cart-tooltip').style.transform = 'translate(0,62px)';
          }}*/
          title={el.children || ""}
        >
          <ListItemText
            className={i == 0 ? "classes.searchIcon" : ""}
            key={"nb" + i}
          >
            <Link className={classes.link} to={`/${el.url}/`}>
              <Typography variant="subtitle1" align="center">
                {formatMessage({ id: el.id || el.url })}
              </Typography>
              {el.icon}
            </Link>
          </ListItemText>
        </Tooltip>
      ))}
    </ListItem>
  );

  const toolbox = [
    {
      id: "account",
      url: props.token ? "products" : "user/auth/login",
      icon: !props.token ? <PersonOutline /> : <Person />,
      children: (
        <Box p={1} style={{ width: "auto" }}>
          <AccountSidebar cart />
        </Box>
      ),
    },
  ];

  return (
    <Box className={classes.grow + " header"}>
      <HideOnScroll {...props}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.header}
            >
              <Box>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  onClick={toggleDrawer}
                  aria-label="open drawer"
                >
                  <MenuIcon />
                </IconButton>
                <Link to={`/`}>
                  <img
                    src="https://uleiuri-cosmetice.ro/uploads/by.root_logo.png"
                    style={{ height: 35, margin: 4 }}
                  />
                </Link>
              </Box>
              {/* <Search className={classes.search} filterName={props.filterName} /> */}
              <Box display="flex">{generateMenu(toolbox, "")}</Box>
              {/* <TopBar/> */}
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          open={state.open}
          onClose={toggleDrawer}
          className={classes.drawer}
          onMouseLeave={() => {
            setState((prev) => ({ ...prev, selectedCategory: -2 }));
          }}
          //variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box display="flex" flexDirection="row">
            <Box
              //className={clsx(classes.list)}
              className={classes.drawerContainer}
              role="presentation"
              width="200px"
              onClick={toggleDrawer}
              onKeyDown={toggleDrawer}
            >
              {staticCategories.map((el) => (
                <Box
                  // onMouseEnter={()=>{
                  //   setState(prev => ({...prev, selectedCategory: el.id}))
                  // }}
                  key={"sc" + el[0]}
                  p={1}
                >
                  <Link to={el[1]}>{el[0]}</Link>
                </Box>
              ))}
            </Box>
          </Box>
        </Drawer>
      </HideOnScroll>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  name: state.account.name,
  email: state.account.email,
  token: state.account.token,
  categories: state.data.categories,
  wishlist: state.wishlist.list,
  cart: state.cartList.cart,
});

export default compose(
  withRouter,
  connect(mapStateToProps /*{filterName}*/),
  withStyles(null, { withTheme: true })
)(injectIntl(NavBar));
