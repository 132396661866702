import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import CKEditors from "react-ckeditor-component";
import SimpleReactValidator from "simple-react-validator";
import {
  TextField,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  FormHelperText,
} from "@material-ui/core";
//import one from '../../../assets/images/pro3/1.jpg'
//import user from '../../../assets/images/user.png';
import { fetchData } from "./../../utils/fetch";
import { showToast } from "./../../services/index";
import { Autocomplete } from "@material-ui/lab";
import { InputLabel } from "@material-ui/core/";
import {
  Add,
  Build,
  Close,
  Delete,
  Link,
  Publish,
  Receipt,
  Remove,
  ViewComfy,
  Storage,
  Highlight,
} from "@material-ui/icons";
import { compose } from "redux";
import { withStyles } from "@material-ui/styles";
import { Divider, ListItemIcon, ListItemText, Switch } from "@material-ui/core";
import { connect } from "react-redux";
import { getImagePath } from "../../services";
import { toast } from "react-toastify";
import { MenuItem } from "@material-ui/core/";
import { Select } from "@material-ui/core/";
import { FormControl } from "@material-ui/core/";
import { DialogTitle } from "@material-ui/core/";
import MyDialog from "./dialog";
import { Prompt } from "react-router-dom";

const styles = (theme) => ({
  input: {
    background: "white",
  },
  textField: {
    "& .MuiInputLabel-root": {
      flex: "1 1 30%",
    },
    "& .MuiFormControl-root": {
      flex: "1 1 70%",
    },
  },
  trTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  list: {
    "& .MuiListItem-root": {
      padding: 4,
    },
    "& .MuiListItemIcon-root": {
      alignSelf: "center",
      justifyContent: "center",
    },
  },
  container: {
    "& .MuiAutocomplete-listbox": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .MuiAutocomplete-option": {
      flex: "0 0 80px",
    },
    "& .related-product .MuiSvgIcon-root": {
      position: "absolute",
      right: 4,
    },
    "& .MuiSelect-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& label": {
      margin: [[8, 0, 4, 0]],
    },
    "& input": {
      padding: 8,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: [[8, 65, 8, 8]],
      "& input.MuiAutocomplete-input": {
        padding: 0,
      },
    },
  },
});

const MyTextField = (props) => (
  <TextField
    {...props}
    label={props.label}
    name={props.name}
    value={props.value}
    multiline={props.multiline}
    onChange={props.onChange}
    helperText={props.helperText}
    error={props.helperText}
    margin={props.margin || "none"}
    className={props.className}
    variant="outlined"
    size="small"
  />
);

const LeftLabelTextField = (props) => (
  <Box
    minWidth={250}
    display="flex"
    className={props.className}
    alignItems="center"
  >
    <InputLabel>{props.label}</InputLabel>
    <MyTextField {...props} className="" label="" />
  </Box>
);

const TopLabelTextField = (props) => (
  <Box minWidth={250} ml={props.ml ? props.ml : 0} mr={props.mr ? props.mr : 0}>
    <InputLabel margin="none">{props.label}</InputLabel>
    <MyTextField {...props} label="" margin="none" />
  </Box>
);


SimpleReactValidator.addLocale("ro", {
  accepted: "Campul :attribute  trebuie sa fie acceptat.",
  after: "Campul :attribute trebuie sa fie dupa :date.",
  after_or_equal: "Campul :attribute trebuie sa fie in sau dupa :date.",
  alpha: "Campul :attribute poate contine doar litere.",
  alpha_space: "Campul :attribute poate contine doar litere si spatii.",
  alpha_num: "Campul :attribute poate contine doar litere si cifre.",
  alpha_num_space:
    "Campul :attribute poate contine doar litere, cifre si spatii.",
  alpha_num_dash:
    "Campul :attribute poate contine doar litere, cifre si linii.",
  alpha_num_dash_space:
    "Campul :attribute poate contine doar litere, cifre, spatii si linii.",
  array: "Campul :attribute trebuie sa fie un tablou.",
  before: "Campul :attribute trebuie sa fie inainte de :date.",
  before_or_equal: "Campul :attribute sa fie in sau inainte :date.",
  between: "Campul :attribute trebuie sa fie intre :min si :max:type.",
  boolean: "Campul :attribute trebuie sa fie binar.",
  card_exp: "Campul :attribute trebuie sa fie o data de expirare valida.",
  card_num: "Campul :attribute trebuie sa fie un numar de card valid.",
  currency: "Campul :attribute trebuie sa fie valuta valida.",
  date: "Campul :attribute trebuie sa fie o data.",
  date_equals: "Campul :attribute trebuie sa fie egala cu :date.",
  email: "Campul :attribute trebuie sa fie o adresa email valida.",
  in: "Campul selectat :attribute tre sa fie din valorile :values.",
  integer: "Campul :attribute trebuie sa fie un intreg.",
  max: "Campul :attribute nu poate depasi :max:type.",
  min: "Campul :attribute nu poate fi sub  :min:type.",
  not_in: "Campul :attribute nu poate fi dintre valorile :values.",
  not_regex: "Campul :attribute nu corespunde.",
  numeric: "Campul :attribute poate doar fi un numar.",
  phone: "Campul :attribute nu e un numar de telefon valid.",
  regex: "Campul :attribute nu corespunde.",
  required: "Camp obligatoriu.", 
  size: "Campul :attribute nu e in lungimea :size:type.",
  string: "Campul :attribute trebuie sa fie un sir de caractere.",
  typeof: "Campul :attribute nu e de tipul :type.",
  url: "Campul :attribute nu e un url.",
});


export class Add_product extends Component {
  constructor(props) {
    super(props);
    this.edit = false;
    this.state = {
      validator: new SimpleReactValidator({ locale: "ro" }),
      panelIndex: 0,
      seoTitle: "",
      seoDescription: "",
      seoMobileDescription: '',
      keywords: '',
      name: "",
      description: "",
      pictures: [],
      picture: [],
      tempPicture: -1,
      media: [],
      rec: [],
      tempProduct: -1,
      products: [],
      categories: [], //selectedCategory: -1,
      inheritedSpecifications: [],
      ownSpecifications: [],
      variant: false,
      mediaOpen: false,
      category: 0,
      mediaSource: 0,
      variations: [{ id: "", name: "", price: "", stock: "", discount: "" }],
      tmpVariation: "",
      unsaved: false,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.getCategorySpecifications();
    this.fetchAllProducts();
    this.fetchMediaPictures();
    if (id) {
      this.fetchSingleProduct(id);
      // this.fetchVariations();
      this.edit = true;
    }
  }

  componentDidUpdate(prev) {
    if (prev.token !== this.props.token) {
      let id = this.props.match.params.id;
      this.getCategorySpecifications();
      this.fetchAllProducts();
      this.fetchMediaPictures();
      if (id) {
        this.fetchSingleProduct(id);
        //this.fetchVariations();
        this.edit = true;
      }
    }
  }

  // componentDidUpdate(prevProps) {
  // 	if (prevProps.token != this.props.token) {
  // 		alert(1);
  // 	}
  // }

  /*   saveVariation = async (description) => {
    try {
      const obj = {
        description: description,
        token: this.props.token,
        email: this.props.email,
      };
      let response = await fetchData(obj, "product/variation/new.php");
      if (response.status === "fail") throw response.message;
      toast.success("Variatie salvata");
      let variations = [...this.state.variations];
      variations.push({ id: response, description: description });
      this.setState({ variations: variations });
    } catch (error) {
      toast.error("Eroare");
      console.log(error);
    }
  }; */

  getCategorySpecifications = async () => {
    try {
      const obj = { token: this.props.token, email: this.props.email };
      let response = await fetchData(obj, "product/category/get.php");
      if (response.status === "fail") throw response.message;
      console.log(response);
      //let parents = [...new Set(response.map(el => el.parent))];
      const categories = response
        .filter((el) => el.final === "1")
        .map((el) => ({
          ...el,
          filters: JSON.parse(el.filters),
        }));
      this.setState({ categories: categories });
    } catch (error) {
      console.log(error);
    }
  };

  fetchMediaPictures = async () => {
    try {
      const obj = { token: this.props.token, email: this.props.email };
      let pictures = await fetchData(obj, "image/get.php");
      if (pictures.status === "fail") throw pictures.message;
      console.log(pictures);
      this.setState({ media: pictures });
    } catch (error) {
      console.log(error);
    }
  };

  fetchSingleProduct = async (id) => {
    try {
      const obj = { id: id, token: this.props.token, email: this.props.email };
      let product = await fetchData(obj, "product/get.php");
      if (product.status === "fail") throw product.message;
      let pictures = JSON.parse(product.pictures) || [];
      let picture = pictures.splice(0, 1);
      this.setState({
        //...product,
        id: product.id,
        name: product.name,
        description: product.description,
        short_details: product.short_details,
        variant: product.variant,
        seoDescription: product.seo_description,
        seoMobileDescription: product.seo_mobile_description,
        seoTitle: product.seo_title,
        keywords: product.keywords,
        rec: product.rec.map((el) => el.id),
        tva: product.tva,
        um: product.um,
        picture: picture,
        pictures: pictures,
        category: product.category,
        price: product.variant ? "" : product.price,
        //variant: product.variant ? true : false,
        variations: JSON.parse(product.variations),
        ownSpecifications: JSON.parse(product.own_specifications),
        inheritedSpecifications:
          product.specifications == "[]"
            ? this.state.categories
                .find((el) => el.id == product.category)?.filters?.map(el => ({t: el.t, l: []}))
            : JSON.parse(product.specifications),
      });
    } catch (error) {
      console.log(error);
    }
  };

  /*fetchVariations = async () => {
    try {
      const obj = { token: this.props.token, email: this.props.email };
      let response = await fetchData(obj, "product/variation/get.php");
      if (response.status === "fail") throw response.message;
      this.setState({ variations: response });
    } catch (error) {
      console.log(error);
    }
  };*/

  fetchAllProducts = async () => {
    try {
      const obj = { token: this.props.token, email: this.props.email };
      let products = await fetchData(obj, "product/get.php");
      if (products.status === "fail") throw products.message;
      this.setState({
        products: products.map((el) => ({
          id: el.id,
          name: el.name,
          pictures: JSON.parse(el.pictures) || [{}],
        })),
      });
    } catch (error) {
      console.log(error);
    }
  };

  submitForm = async (e) => {
    e.preventDefault();
    if (!this.state.validator.allValid()) {
      this.state.validator.showMessages();
      this.forceUpdate();
      return "";
    }
    let endpoint = this.edit ? "edit" : "new";
    const obj = {
      token: this.props.token,
      email: this.props.email,
      id: this.props.match.params.id,
      seo_title: this.state.seoTitle,
      seo_description: this.state.seoDescription,
      seo_mobile_description: this.state.seoMobileDescription,
      keywords: this.state.keywords,
      name: this.state.name,
      variations: this.state.variant
        ? this.state.variations
        : [this.state.variations[0]],
      variant: this.state.variant,
      description: this.state.description,
      short_details: this.state.short_details,
      pictures: [...this.state.picture, ...this.state.pictures].map(
        (el) => el.id
      ),
      rec: this.state.rec,
      category: this.state.category,
      specifications: JSON.stringify(this.state.inheritedSpecifications),
      own_specifications: JSON.stringify(this.state.ownSpecifications),
    };
    try {
      const response = await fetch(
        "https://admin.uleiuri-cosmetice.ro/api/rest/product/" +
          endpoint +
          ".php",
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "fail") throw data.message;
      console.log(data);
      showToast("Produsul a fost salvat", "success");
      this.setState({ unsaved: false });
    } catch (error) {
      showToast("Produsul nu s-a salvat", "error");
    }
  };

  handleChange = (e) => {
    this.setState({ stock: parseInt(e.target.value), unsaved: true });
  };

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState({ [event.target.name]: event.target.value, unsaved: true });
  };

  setStateFromValue = (name, value) => {
    this.setState({ [name]: value, unsaved: true });
  };

  updateContent(newContent) {
    this.setState({ description: newContent, unsaved: true });
  }

  onBlur = (evt) => {
    //console.log("onBlur event called with event info: ", evt);
  };

  afterPaste = (evt) => {
    //console.log("afterPaste event called with event info: ", evt);
  };

  onChange = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({ description: newContent, unsaved: true });
  };

  onChangeCategoryOption = (e, value) => {
    if (value == null)
      this.setState({
        category: -1,
        inheritedSpecifications: [],
      });
    else
      this.setState({
        category: value.id,
        inheritedSpecifications:
        this.state.categories
                .find((el) => el.id == value.id)?.filters?.map(el => ({t: el.t, l: [""]})),
        unsaved: true,
      });
  };

  onChangeRelatedOption = (e, value) => {
    if (value == null) this.setState({ tempProduct: -1 });
    else this.setState({ tempProduct: value.id });
  };

  onChangePictureOption = (e, value) => {
    if (value == null) this.setState({ tempPicture: -1 });
    else this.setState({ tempPicture: value });
  };

  setSpecification = (i, v) => {
    let inheritedSpecifications = [...this.state.inheritedSpecifications];
    //if (inheritedSpecifications[i].l[el].indexOf(v) !== -1 || v.trim() === "") return;
    if (inheritedSpecifications[i]?.l)
      inheritedSpecifications[i].l = [
        ...inheritedSpecifications[i].l,
        v];
    else {
 inheritedSpecifications = [...inheritedSpecifications, { t: this.state.categories.find(el => el.id == this.state.category).filters[i].t, l: [v] }]
    
    }
    this.setState({ inheritedSpecifications: inheritedSpecifications, unsaved: true });
  };

  setOwnSpecification = (e,i) => {
    let ownSpecifications = [...this.state.ownSpecifications];
    ownSpecifications[i].t = e.target.value;
    this.setState({ ownSpecifications: ownSpecifications });
  }

  setValue = (e, i, j) => {
      let ownSpecifications = [...this.state.ownSpecifications];
    //if (inheritedSpecifications[i].l[el].indexOf(v) !== -1 || v.trim() === "") return;
    ownSpecifications[i].l[j] = e.target.value;
    /*if (ownSpecifications[i]?.l)
      ownSpecifications[i].l = [
        ...ownSpecifications[i].l,
        e.target.value];
    else {
 ownSpecifications = [...ownSpecifications, { t: this.state.ownSpecifications[i].t, l: [e.target.value] }]
    
    }*/
    this.setState({ ownSpecifications: ownSpecifications, unsaved: true });
  }

  removeSpecification = (i, j) => {
    let inheritedSpecifications = [...this.state.inheritedSpecifications];
    inheritedSpecifications[i].l.splice(j, 1);
    this.setState({ inheritedSpecifications: inheritedSpecifications, unsaved: true });
  };

  removeOwnSpecification = (i) => {
    let ownSpecifications = [...this.state.ownSpecifications];
    ownSpecifications.splice(i, 1);
    this.setState({ ownSpecifications: ownSpecifications, unsaved: true });
  };

      removeValue = (i, j) => {
    let ownSpecifications = [...this.state.ownSpecifications];
    ownSpecifications[i].l.splice(j, 1);
    this.setState({ ownSpecifications: ownSpecifications, unsaved: true });
  };

  addOwnSpecificationCategory = (e, i) => {
      this.setState({ownSpecifications: [...this.state.ownSpecifications, { t: "", l: [] }],});
  };

  addOwnSpecificationValue = (e, i) => {
    let ownSpecifications = [...this.state.ownSpecifications];
    ownSpecifications[i].l = [...ownSpecifications[i].l, ""]
    this.setState(({
      ownSpecifications: ownSpecifications,
    }));
  };

  removeRelated = (id) => {
    this.setState({
      rec: this.state.rec.filter((el) => el != id),
      unsaved: true,
    });
  };

  removePicture = (id) => {
    this.setState({
      pictures: this.state.pictures.filter((el) => el.id != id),
      unsaved: true,
    });
  };

  addRelatedProduct = () => {
    let rec = [...this.state.rec];
    rec.push(this.state.tempProduct);
    document.querySelector("#related-option-autocomplete").value = "";
    this.setState({ rec: rec, tempProduct: -1, unsaved: true });
  };

  addPicture = () => {
    this.setState({
      pictures: [...this.state.pictures, this.state.tempPicture],
      tempPicture: -1,
      unsaved: true,
    });
  };

  addVariation = () => {
    let variations = [...this.state.variations];
    //id ? price.push([value, 0, id]) : price.push([value, 0]);
    const variation = Object.keys(variations[0]).reduce(
      (acc, el) => ({ ...acc, [el]: "" }),
      {}
    );
    variations.push(variation);
    this.setState({ variations: variations });
  };

  changeVariation = (i, e) => {
    let variations = [...this.state.variations];
    variations[i][e.target.name] = e.target.value;
    this.setState({ variations: variations, unsaved: true });
  };

  removeVariation = (i) => {
    let variations = [...this.state.variations];
    variations.splice(i, 1);
    this.setState({ variations: variations, unsaved: true });
  };

  toggleVariant = (e) => {
    this.setState({
      variant: e.target.value,
      unsaved: true,
      panelIndex:
        this.state.panelIndex +
        (this.state.panelIndex >= 2 ? (e.target.value ? 1 : -1) : 0),
    });
  };

  onChangePanel = (index) => {
    this.setState({ panelIndex: index });
  };

  setMainPicture = (picture) => {
    this.setState({ picture: picture, unsaved: true });
  };

  setMainGallery = (pictures) => {
    this.setState({ pictures: pictures, unsaved: true });
  };

  render() {
    const {
      categories,
      category,
      panelIndex,
      variant,
      picture,
      pictures,
    } = this.state;
    let variations = variant
      ? this.state.variations
      : this.state.variations.length
      ? [this.state.variations[0]]
      : [];
    const { classes } = this.props;
    const filters =
      category && categories && categories.length
        ? categories.find((el) => el.id == category).filters
        : [];
    const productItems = variant
      ? [
          ["Variatii", <ViewComfy />],
          ["Specificatii", <Storage />],
          ["Produse recomandate", <Link />],
          ["SEO", <Highlight />],
        ]
      : [
          ["Generale", <Build />],
          ["Specificatii", <Storage />],
          ["Produse recomandate", <Link />],
          ["SEO", <Highlight />],
        ];

    return (
      <Fragment>
        <Prompt
          when={this.state.unsaved}
          message="Ai modificari nesalvate, sigur vrei sa parasesti pagina?"
        />
        <Breadcrumb title="Produs" list={[["/products/", "Produse"]]} />
        <div className={"container-fluid " + classes.container}>
          <Box display="flex">
            <Box flex="1 1 70%">
              <Box mx={1} mb={2} display="grid">
                <MyTextField
                  className={
                    classes.input //label="Nume produs"
                  }
                  margin="none"
                  helperText={this.state.validator.message(
                    "name",
                    this.state.name,
                    "required|string"
                  )}
                  name="name"
                  value={this.state.name}
                  onChange={this.setStateFromInput}
                />
              </Box>
              <Box mx={1} mb={2}>
                <CKEditors
                  activeclassName="p10"
                  content={this.state.description}
                  events={{
                    blur: this.onBlur,
                    afterPaste: this.afterPaste,
                    change: this.onChange,
                  }}
                />
                <FormHelperText error>
                  {this.state.validator.message(
                    "description",
                    this.state.description,
                    "required|string"
                  )}
                </FormHelperText>
              </Box>
              <Box
                mx={1}
                mb={2}
                p={1}
                bgcolor="white"
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
              >
                <InputLabel>Descriere scurta</InputLabel>
                <MyTextField
                  className={
                    classes.input //label="Nume produs"
                  }
                  margin="none"
                  helperText={this.state.validator.message(
                    "short_details",
                    this.state.short_details,
                    "string"
                  )}
                  multiline
                  name="short_details"
                  value={this.state.short_details}
                  onChange={this.setStateFromInput}
                />
              </Box>
              <Box mx={1} mb={2} p={1} pb={0} bgcolor="white">
                <Box display="flex" pb={1} position="relative">
                  <InputLabel>Tip produs:&nbsp;</InputLabel>
                  <FormControl variant="outlined">
                    <Select value={variant} onChange={this.toggleVariant}>
                      <MenuItem value={0}>Simplu</MenuItem>
                      <MenuItem value={1}>Cu variatii</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box mx={-1}>
                  <Divider />
                </Box>
                {/* <FormControlLabel control={<Switch checked={variant} />} label="Variatii" /> */}
                <Box mx={-1} display="flex">
                  <Box
                    flex="0 0 auto"
                    borderColor="rgba(0, 0, 0, 0.12)"
                    border={1}
                    bgcolor="#e9ebee"
                  >
                    <List
                      disablePadding
                      className={classes.list}
                      component="nav"
                      aria-label="main mailbox folders"
                    >
                      {productItems.map((el, i) => (
                        <Fragment key={"pi" + el[0]}>
                          <ListItem
                            divider
                            onClick={() => this.onChangePanel(i)}
                            button
                            selected={i == this.state.panelIndex}
                            disableGutters
                          >
                            <ListItemIcon>{el[1]}</ListItemIcon>
                            <ListItemText primary={el[0]} />
                          </ListItem>
                        </Fragment>
                      ))}
                    </List>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box display={panelIndex == 0 ? "block" : "none"} p={1}>
                    <Box>
                      <Box display="flex" className={classes.textField}>
                        {variations[0] &&
                          Object.keys(variations[0]).map((el) => (
                            <InputLabel
                              key={"vt" + el}
                              className={classes.trTextField}
                            >
                              {el}
                            </InputLabel>
                          ))}
                        <Box m={0.5} width="24px" />
                      </Box>
                      {variations.map((el, i) => (
                        <Box
                          key={"pv" + el.name}
                          display="flex"
                          alignItems="center"
                        >
                          {Object.keys(el).map((_el) => (
                            <MyTextField
                              key={"v" + _el + i}
                              className={classes.trTextField}
                              margin="none"
                              disabled={_el == "id"}
                              helperText={
                                _el == "id"
                                  ? ""
                                  : this.state.validator.message(
                                      _el+i,
                                      variations[i][_el],
                                      _el == "price"
                                        ? "required|numeric|min:0,num"
                                        : _el == "discount"
                                        ? "required|min:0,num|max:1,num|numeric"
                                        : _el == "stock"
                                        ? "required|min:0,num|integer"
                                        : "string"
                                    )
                              }
                              name={_el}
                              onChange={(e) => this.changeVariation(i, e)}
                              value={el[_el]}
                            />
                          ))}

                          {variant ? (
                            <Close
                              className="delete"
                              onClick={(e) => this.removeVariation(i)}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      ))}
                      <FormHelperText error>
                        {this.state.validator.message(
                          "variation",
                          this.state.variations,
                          "required|min:1,array"
                        )}
                      </FormHelperText>
                      {variant ? (
                        <Box display="inline-block">
                          <Button
                            className={classes.tTextField}
                            onClick={() => this.addVariation("", "")}
                          >
                            Adauga
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  <Box display={panelIndex == 1 ? "block" : "none"} p={1}>
                    {filters.length
                      ? filters.map((el, i) => (
                        <Box
                          key={"fpl" + el.t + i}
                          display="flex"
                          flexDirection="column"
                        >
                          <InputLabel>{el.t}</InputLabel>
                          {(this.state.inheritedSpecifications[i]?.l||[])
                            .map((el,j) => (
                              <>
                              <Box key={"spc" + i + el} display="flex">
                                <Typography>{el}</Typography>
                                <Close onClick={(e) => this.removeSpecification(i,j)} />
                              </Box>
                              <FormHelperText error>
                                  {this.state.validator.message(
                                    "Campul",
                                    el,
                                    "string"
                                  )}
                                </FormHelperText>
                                
                                                                </>
                            ))}
                          <Autocomplete
                                  /*value={
                                    this.state.inheritedSpecifications[i].l[ this.state.inheritedSpecifications[i].l.length-1]
                                  }*/
                            options={
                              this.state.inheritedSpecifications[i]?.l ?
                              categories.find((el) => el.id == category)
                                  .filters[i].l.filter(el => this.state.inheritedSpecifications[i].l.indexOf(el) === -1)
                            : categories.find((el) => el.id == category)
                                  .filters[i].l}
                                  getOptionLabel={(option) => option + ""}
                                  openOnFocus
                                  //disablePortal
                                  freeSolo
                                  /*defaultValue={
                                    this.state.inheritedSpecifications[i]?.l[0] || ""
                                  }*/
                                  label={el}
                                  onChange={(e, v) =>
                                    this.setSpecification(i, v)
                                  }
                                  renderOption={(option) => option}
                                  renderInput={(params) => (
                                    <Fragment>
                                      <TopLabelTextField
                                        {...params}
                                        /*label={el.l[0]}*/
                                      />
                                    </Fragment>
                                  )}/>
                        </Box>
                      ))
                      : ''}
                    {
                    <Box width="100%" p={1}>
                    {this.state.ownSpecifications.length
                      ? this.state.ownSpecifications.map((el, i) => (
                          <Box key={i} display="flex" flexDirection="column">
                            <Box display="flex" alignItems="center">
                              <LeftLabelTextField
                                className={[
                                  classes.tTextField,
                                  classes.textField,
                                ].join(" ")}
                                label={"Titlu"}
                                style={{ width: 200 }}
                              value={el.t}
                              name={el.t}
                                helperText={this.state.validator.message(
                                  "Titlu",
                                  el.t,
                                  "required|min:3,string"
                                )}
                                error={this.state.validator.message(
                                  "Titlu",
                                  el.t,
                                  "required|min:3,string"
                                )}
                                onChange={(e) => this.setOwnSpecification(e,i)}
                              />
                              <Close
                                className="delete"
                                onClick={() => this.removeOwnSpecification(i)}
                              />
                            </Box>
                            {el.l.map((_el, j) => (
                              <Box key={j}>
                                <Box display="flex" alignItems="center">
                                  <LeftLabelTextField
                                    className={[
                                      classes.tTextField,
                                      classes.textField,
                                    ].join(" ")}
                                    style={{
                                      marginLeft: 20,
                                      width: 200,
                                    }}
                                    helperText={this.state.validator.message(
                                      "Valoare",
                                      el.t+j,
                                      "string|min:1"
                                    )}
                                    error={this.state.validator.message(
                                      "Valoare",
                                      el.t+j,
                                      "string|min:1"
                                    )}
                                    label={"Valoare"}
                                    value={_el}
                                    name={el.t+j}
                                    onChange={(e) =>
                                      this.setValue(e, i, j)
                                    }
                                  />
                                  <Close
                                    className="delete"
                                    onClick={() =>
                                      this.removeValue(i,j)
                                    }
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Box style={{ width: 200 }}>
                              <Button
                                variant="outlined"
                                onClick={(e) => this.addOwnSpecificationValue(e, i)}
                                startIcon={<Add />}
                              >
                                Valoare
                              </Button>
                            </Box>
                          </Box>
                        ))
                      : ""}
                    <Button
                      variant="outlined"
                      onClick={this.addOwnSpecificationCategory}
                      startIcon={<Add />}
                    >
                      Categorie
                    </Button>
                  </Box>
                    }
                                     
                  </Box>
                  <Box
                    display={panelIndex == 2 ? "block" : "none"}
                    p={1}
                    width="100%"
                  >
                    <Box display="flex" flexWrap="wrap">
                      {this.state.products
                        .filter((el) => this.state.rec.indexOf(el.id) >= 0)
                        .map((el) => (
                          <Box
                            p={1}
                            key={"rcs" + el.id}
                            className="related-product"
                            width="25%"
                            display="flex"
                            flexDirection="column"
                            position="relative"
                          >
                            <img
                              alt={el.name}
                              title={el.name}
                              style={{ objectFit: "cover" }}
                              srcSet={
                                el.pictures[0].sizes
                                  ? el.pictures[0].sizes
                                      .split(",")
                                      .map((res) =>
                                        getImagePath(el.pictures[0], res)
                                      )
                                      .join(", ")
                                  : ""
                              }
                              sizes="(max-width: 768px) 300px, 100px"
                              src={getImagePath(el.pictures[0], "")}
                            />
                            <InputLabel
                              style={{
                                fontSize: 10,
                                height: "3em",
                                overflow: "hidden",
                              }}
                            >
                              {el.name}
                            </InputLabel>
                            <Close
                              className="delete"
                              onClick={() => this.removeRelated(el.id)}
                            />
                          </Box>
                        ))}
                    </Box>
                    <Autocomplete
                      id="related-option-autocomplete"
                      options={this.state.products.filter(
                        (el) =>
                          el.id != this.props.match.params.id &&
                          this.state.rec.indexOf(el.id) == -1
                      )}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      disablePortal
                      onChange={this.onChangeRelatedOption}
                      renderOption={(option) => (
                        <Box display="flex" flexDirection="column">
                          <img
                            alt={option.name}
                            title={option.name}
                            style={{ objectFit: "cover" }}
                            srcSet={
                              option.pictures[0].sizes
                                ? option.pictures[0].sizes
                                    .split(",")
                                    .map((res) =>
                                      getImagePath(option.pictures[0], res)
                                    )
                                    .join(", ")
                                : ""
                            }
                            sizes="(max-width: 768px) 300px, 100px"
                            src={getImagePath(option.pictures[0], "")}
                          />
                          <InputLabel
                            style={{
                              fontSize: 10,
                              height: "3em",
                              overflow: "hidden",
                            }}
                          >
                            {option.name}
                          </InputLabel>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TopLabelTextField {...params} />
                      )}
                    />
                    <Button
                      disabled={this.state.tempProduct == -1}
                      onClick={this.addRelatedProduct}
                    >
                      Adauga
                    </Button>
                  </Box>
                  <Box
                    display={panelIndex == 3 ? "block" : "none"}
                    p={1}
                    width="100%"
                  >
                    <TopLabelTextField
                      className={classes.tTextField}
                      label="Titlu"
                      name="seoTitle"
                      helperText={this.state.validator.message(
                        "Title",
                        this.state.seoTitle,
                        "string"
                      )}
                      value={this.state.seoTitle}
                      onChange={this.setStateFromInput}
                    />
                    <Typography style={{ background: (this.state.seoTitle.length < 50 ? 'yellow' : this.state.seoTitle.length <= 70 ? 'green' : 'red') }}>
                      Lungime titlu: {this.state.seoTitle.length}/70
                    </Typography>
                    <TopLabelTextField
                      className={classes.tTextField}
                      label="Descriere"
                      name="seoDescription"
                      helperText={this.state.validator.message(
                        "Descriere",
                        this.state.seoDescription,
                        "string"
                      )}
                      multiline
                      value={
                        this.state.seoDescription //helperText={ this.state.validator.message("Description", this.state.seoDescription, "required")}
                      }
                      onChange={this.setStateFromInput}
                    />
                    <Typography style={{ background: (this.state.seoDescription.length < 120 ? 'yellow' : this.state.seoDescription.length <= 146 ? 'green' : 'red')}}>
                      Lungime descriere desktop: {this.state.seoDescription.length}/146
                    </Typography>
                    <TopLabelTextField
                      className={classes.tTextField}
                      label="Descriere Mobile"
                      name="seoMobileDescription"
                      helperText={this.state.validator.message(
                        "Descriere Mobile",
                        this.state.seoMobileDescription,
                        "string"
                      )}
                      multiline
                      value={
                        this.state.seoMobileDescription //helperText={ this.state.validator.message("Description", this.state.seoDescription, "required")}
                      }
                      onChange={this.setStateFromInput}
                    />
                    <Typography style={{ background: (this.state.seoMobileDescription.length < 100 ? 'yellow' : this.state.seoMobileDescription.length <= 120 ? 'green' : 'red') }}>
                      Lungime descriere mobile: {this.state.seoMobileDescription.length}/120
                    </Typography>
                    <TopLabelTextField
                      className={classes.tTextField}
                      label="Keywords"
                      name="keywords"
                      helperText={this.state.validator.message(
                        "Keywords",
                        this.state.keywords,
                        "string"
                      )}
                      value={this.state.keywords}
                      onChange={this.setStateFromInput}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box flex="1 1 30%">
              <Box mx={1} mb={2}>
                <Button onClick={this.submitForm} variant="outlined">
                  Salveaza
                </Button>
              </Box>
              <Box mx={1} mb={2} p={1} bgcolor="white">
                <InputLabel component="h2">Categorie produs</InputLabel>
                {categories.length && (
                  <Autocomplete
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    openOnFocus
                    disableClearable
                    defaultValue={
                      categories.find((el) => el.id == category) //disablePortal
                    }
                    onChange={this.onChangeCategoryOption}
                    renderInput={(params) => (
                      <TextField
                        helperText={this.state.validator.message(
                          "Categorii",
                          category,
                          "min:1,num|integer|required"
                        )}
                        error={this.state.validator.message(
                          "Categorii",
                          category,
                          "min:1,num|integer|required"
                        )}
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </Box>
              <Box mx={1} mb={2} p={1} bgcolor="white">
                <InputLabel component="h2">Imagine produs</InputLabel>
                <Box>
                  {picture.length
                    ? picture.map((el) => (
                        <img
                          width="25%"
                          height="auto"
                          alt=""
                          key={"_pic" + el.id}
                          style={{
                            objectFit: "cover",
                            padding: 4,
                          }}
                          srcSet={
                            el.sizes
                              ? el.sizes
                                  .split(",")
                                  .map((res) => getImagePath(el, res))
                                  .join(", ")
                              : ""
                          }
                          sizes="(max-width: 768px) 300px, 100px"
                          src={getImagePath(el, "")}
                        />
                      ))
                    : ""}
                  <FormHelperText error>
                    {this.state.validator.message(
                      "Imagine principala",
                      picture,
                      "size:1,array|required"
                    )}
                  </FormHelperText>
                </Box>
                <MyDialog
                  pictures={this.state.picture}
                  setMainPicture={this.setMainPicture}
                />
              </Box>

              <Box mx={1} mb={2} p={1} bgcolor="white">
                <InputLabel component="h2">Galerie</InputLabel>
                <Box display="flex" flexWrap="wrap">
                  {pictures.length
                    ? pictures.map((el) => (
                        <img
                          width="25%"
                          height="fit-content"
                          alt=""
                          key={"p" + el.id}
                          style={{
                            objectFit: "cover",
                            padding: 4,
                          }}
                          srcSet={
                            el.sizes
                              ? el.sizes
                                  .split(",")
                                  .map((res) => getImagePath(el, res))
                                  .join(", ")
                              : ""
                          }
                          sizes="(max-width: 768px) 300px, 100px"
                          src={getImagePath(el, "")}
                        />
                      ))
                    : ""}
                </Box>
                <MyDialog
                  multiple
                  pictures={this.state.pictures}
                  setMainPicture={this.setMainGallery}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Add_product);
