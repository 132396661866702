import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { setUser, setMessage } from '../../actions';
//import Breadcrumb from "..//common/breadcrumb";
import { withFirebase } from '../firebase';
import {
	Box,
	Button,
	FormControl,
	InputAdornment,
	InputLabel,
	IconButton,
	OutlinedInput,
	TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/styles';
import SimpleReactValidator from 'simple-react-validator';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Visibility, VisibilityOff } from '@material-ui/icons/';

const INITIAL_STATE = {
	validator: new SimpleReactValidator(),
	password1: '',
	password2: '',
	error: null,
	showPassword: false
};

const useStyles = (theme) => ({
	form: {
		maxWidth: 500,
		flexWrap: 'wrap',
		'& .MuiFormControl-root': {
			marginLeft: 16,
			marginRight: 16,
			flex: [ [ 1, 0, '200px' ] ]
		}
	},
	// edit: {
	//   '&:not(.Mui-disabled)': {
	//     cursor: 'pointer',
	//     color: '#ffffff',
	//     backgroundColor: '#4caf50',
	//   }
	// },
	delete: {
		cursor: 'pointer',
		color: '#ffffff',
		backgroundColor: '#f44336'
	}
});

class ChangePasswordFormBase extends Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	onSubmit = async (event) => {
		event.preventDefault();
		if (!this.state.validator.allValid()) {
			this.state.validator.showMessages();
			this.forceUpdate();
			return '';
		}
		const password1 = this.state.password1;
		try {
			await this.props.firebase.auth().currentUser.updatePassword(password1);
			this.setState({ ...INITIAL_STATE, message: '' });
			setUser(null);
			setMessage('Password changed');
			//this.setState({email:''});
			this.props.history.push('/user/login/');
		} catch (error) {
			console.log(error);
			this.setState({ error });
		}
	};

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleChange = (prop) => (event) => {
		this.setState({ [prop]: event.target.value });
	};

	setStateFromInput = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleClickShowPassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};

	handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	render() {
		const { password1, password2, error } = this.state;

		const isInvalid = password1 !== password2 || password1 === '';

		const { classes } = this.props;
		return (
			<Box className={[ classes.form, classes.element ].join(' ')}>
				{this.props.token ? '' : <Redirect to="/user/login/" />}
				<Box display="flex">
					<FormControl
						margin="normal"
						className={'MuiTextField-root'}
						/*className={clsx(classes.margin, classes.textField)}*/ variant="outlined"
					>
						<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={this.state.showPassword ? 'text' : 'password'}
							value={this.state.password1}
							onChange={this.handleChange('password1')}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={this.handleClickShowPassword}
										onMouseDown={this.handleMouseDownPassword}
										edge="end"
									>
										{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							labelWidth={70}
						/>
					</FormControl>
					{this.state.validator.message('password', this.state.password1, 'required')}
					<TextField
						label="Confirm password"
						name="password2"
						value={password2}
						onChange={this.onChange}
						margin="normal"
						type="password"
						variant="outlined"
					/>
					{this.state.validator.message('password', this.state.password2, 'required')}
				</Box>
				<Box>
					<Button
						align="center"
						className={classes.edit}
						disabled={isInvalid}
						variant="outlined"
						onClick={this.onSubmit}
						type="submit"
					>
						Change password
					</Button>
				</Box>
				{error && <p>{error.message}</p>}
			</Box>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.account.token
});

export default compose(connect(mapStateToProps), withStyles(useStyles, { withTheme: true }), withRouter, withFirebase)(
	ChangePasswordFormBase
);
