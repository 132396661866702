import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Tab, Tabs, DialogActions } from '@material-ui/core/';
import Media from '../media/gallery';
import Upload from '../media/upload';
import { Fragment } from 'react';
import { useEffect } from 'react';

const MyDialog = (props) => {
	const [ state, setState ] = useState({
		mediaOpen: false,
		media: [],
		mediaSource: 0,
		uploadedPicture: {},
		pictures: []
	});

	useEffect(
		() => {
			setState((prev) => ({ ...prev, pictures: props.pictures }));
		},
		[ props.pictures ]
	);

	const toggleDialog = () => {
		setState((prev) => ({ ...prev, mediaOpen: !state.mediaOpen }));
	};

	const fromUploadToMedia = (picture) => {
		//const pictures = [ ...state.media ];
		//pictures.unshift(picture);
		setState((prev) => ({ ...prev, uploadedPicture: picture, mediaSource: 0 }));
	};

	const setStateFromValue = (name, value) => {
		setState({ ...state, [name]: value });
	};

	const selectMainPicture = (picture, action) => {
		let pictures = [ ...state.pictures ];
		if (props.multiple)
			action === 'delete' ? (pictures = pictures.filter((el) => el.id != picture.id)) : pictures.push(picture);
		else action === 'delete' ? (pictures = []) : (pictures[0] = picture);
		setState((prev) => ({ ...prev, pictures: pictures }));
	};

	const setMainPicture = () => {
		props.setMainPicture(state.pictures);
		toggleDialog();
	};

	return (
		<Fragment>
			<Button onClick={toggleDialog}>{props.uploadedPicture ? 'Schimba' : 'Adauga'}</Button>
			<Dialog
				open={state.mediaOpen}
				onClose={toggleDialog}
				maxWidth={'xl'}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" />
				<DialogContent>
					<Tabs
						value={state.mediaSource}
						onChange={(e, v) => setStateFromValue('mediaSource', v)}
						aria-label="simple tabs example"
					>
						<Tab label="Galerie" value={0} />
						<Tab label="Upload" value={1} />
					</Tabs>
					<Media
						display={state.mediaSource == 0}
						dialog
						onSelect={selectMainPicture}
						selected={state.pictures.map((el) => el.id)}
						uploadedPicture={state.uploadedPicture}
					/>
					<Upload display={state.mediaSource == 1} onUploadPicture={fromUploadToMedia} />
				</DialogContent>
				<DialogActions>
					<Button onClick={toggleDialog}>Inapoi</Button>
					<Button disabled={!state.pictures.length} onClick={setMainPicture} autoFocus>
						Salveaza
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default MyDialog;
