const api = 'https://admin.uleiuri-cosmetice.ro/api/rest/';

export const fetchData = (data, url) => {
	return fetch(api + url, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((response) => response.json()).catch(e => { alert(e); return {} });
};
