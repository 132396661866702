import React from "react";
import Datatable from "../common/datatable/datatable";
import { fetchData } from "../../utils/fetch";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import dayjs from "dayjs";

const Orders = (props) => {
  const [state, setState] = useState({
    orders: [],
  });

  useEffect(
    () => {
      getOrders();
    },
    [props.token]
  );

  const getOrders = async () => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
      };
      let response = await fetchData(obj, "order/get.php");
      if (response.status === "fail") throw response.message;
      console.log(response);
      //let parents = [...new Set(response.map(el => el.parent))];
      const orders = response.map((el) => ({
        ...el,
        created: dayjs(el.created * 1000).format("DD MMM YYYY HH:mm"),
        shippingInfo: Object.entries(JSON.parse(el.shippingInfo))
          .map((el) => el[0] + ": " + el[1])
          .reduce((acc, el) => acc + "\n" + el, ""),
      }));
      //response.filter(el => parents.indexOf(el.id) === -1).map(el => ({...el, filters: JSON.parse(el.filters) }) )
      setState((prev) => ({
        ...prev,
        orders: orders,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <Link to={`/order/new/`}>Adauga o comanda noua</Link> */}
      <Datatable
        //edit={edit}
        //remove={remove}
        path="order"
        myData={[...state.orders]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Orders);
