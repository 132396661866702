import React, { Component, Fragment, useState, useCallback, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
//import { withFirebase } from "../../firebase";
//import Breadcrumb from "../breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { fetchMenu, fetchData } from "../../utils/fetch";

//import data from './../../../../all_in_one_multikart_react/front-end/src/admin/assets/data/digital-category';
import { TextField, OutlinedInput, InputAdornment, Typography, Divider, Checkbox } from '@material-ui/core';
import { FormControl, Button, Badge } from '@material-ui/core';
import { InputLabel, IconButton } from '@material-ui/core/';
import { Visibility, VisibilityOff, Facebook } from "@material-ui/icons";
//import { setToken, setUser, setName } from "../../actions";
import { Box } from '@material-ui/core';
import { withFirebase } from '../../components/firebase';
import { setCart, setToken, setUid, setWishlist, setType } from '../../actions/index';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import { setUser, addToWishlist } from './../../actions/index'

const clientId =
  '368342962904-7f81duudhflmigkhb2291bcnav7jultg.apps.googleusercontent.com';

SimpleReactValidator.addLocale('ro', {
  accepted             : 'Campul :attribute trebuie sa fie acceptat.',
  after                : 'Campul :attribute trebuie sa fie dupa :date.',
  after_or_equal       : 'Campul :attribute trebuie sa fie in sau dupa :date.',
  alpha                : 'Campul :attribute poate contine doar litere.',
  alpha_space          : 'Campul :attribute poate contine doar litere si spatii.',
  alpha_num            : 'Campul :attribute poate contine doar litere si cifre.',
  alpha_num_space      : 'Campul :attribute poate contine doar litere, cifre si spatii.',
  alpha_num_dash       : 'Campul :attribute poate contine doar litere, cifre si linii.',
  alpha_num_dash_space : 'Campul :attribute poate contine doar litere, cifre, spatii si linii.',
  array                : 'Campul :attribute trebuie sa fie un tablou.',
  before               : 'Campul :attribute trebuie sa fie inainte de :date.',
  before_or_equal      : 'Campul :attribute sa fie in sau inainte :date.',
  between              : 'Campul :attribute trebuie sa fie intre :min si :max:type.',
  boolean              : 'Campul :attribute trebuie sa fie binar.',
  card_exp             : "Campul :attribute trebuie sa fie o data de expirare valida.",
  card_num             : 'Campul :attribute trebuie sa fie un numar de card valid.',
  currency             : 'Campul :attribute trebuie sa fie valuta valida.',
  date                 : 'Campul :attribute trebuie sa fie o data.',
  date_equals          : 'Campul :attribute trebuie sa fie egala cu :date.',
  email                : 'Campul :attribute trebuie sa fie o adresa email valida.',
  in                   : 'Campul selectat :attribute tre sa fie din valorile :values.',
  integer              : 'Campul :attribute trebuie sa fie un intreg.',
  max                  : 'Campul :attribute nu poate depasi :max:type.',
  min                  : 'Campul :attribute nu poate fi sub  :min:type.',
  not_in               : 'Campul :attribute nu poate fi dintre valorile :values.',
  not_regex            : 'Campul :attribute nu corespunde.',
  numeric              : 'Campul :attribute poate doar fi un numar.',
  phone                : 'Campul :attribute nu e un numar de telefon valid.',
  regex                : 'Campul :attribute nu corespunde.',
  required             : 'Campul :attribute e obligatoriu.',
  size                 : 'Campul :attribute nu e in lungimea :size:type.',
  string               : 'Campul :attribute trebuie sa fie un sir de caractere.',
  typeof               : "Campul :attribute nu e de tipul :type.",
  url                  : 'Campul :attribute nu e un url.',
});


const useStyles = makeStyles(theme => ({
  edit: {
      backgroundColor: '#4caf50',
      color: 'white',
  },
}))

const Form = (props) => {
	const [state, setState] = React.useState({
		validator: new SimpleReactValidator({locale: 'ro'}),
		error: '',
		token: '',
		email: '',
		amount: '',
		password: '',
		weight: '',
    weightRange: '',
    uid: '',
    isUser: '',
    emailVerified: true,
    authError: '',
		showPassword: false,
	});
  const classes = useStyles();
  const {firebase, location, history, intl: {formatMessage}} = props;

  useEffect(()=>{
    setState(prev => ({
        ...prev, 
        validator: new SimpleReactValidator({locale: 'ro'}) }))
  },[props.match.params.opt])

  const constCreateDBUser = async ({uid, email, name, token, source}) => {
    try {
        // await firebase.setPersistence('none');
      const obj = { email: email, name: name, uid: uid, token: token, source: source }
      let response = await fetchData( obj, "user/new.php");
      if (response.status === "fail") 
        throw response.message;
      setToken(token);
      setUser(email, name);
      setType(source);
      props.history.push('/');
    } catch (error) {
      console.log(error);
      setState(prev => ({
        ...prev, 
        authError: error }))
    }
  }

  const onSuccess = async (result, provider) => {
    const token = result.credential.idToken || await result.user.getIdToken();
    const {id, name, email} = result.additionalUserInfo.profile
    constCreateDBUser({uid: id, email: email, name: name, token: token, source: provider})
    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    //var accessToken = credential.accessToken;
  }
  const onFailure = (error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
  }
  const providerSignIn = (source) => {
    const firebase = props.firebase;
    var provider = source == 'google'
    ? new firebase.auth.GoogleAuthProvider()
    : new firebase.auth.FacebookAuthProvider()
    provider.setCustomParameters({
      'display': 'popup'
    });
    provider.addScope('email');
    firebase.auth().useDeviceLanguage();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => onSuccess(result, source))
      .catch(onFailure);
  }

  const createUser = async (e) => {
    e.preventDefault();
		if (!state.validator.allValid()) {
			state.validator.showMessages();
			setState({...state, "error": !state.validator.allValid()});
			return "";
		}

		try {
     // await firebase.setPersistence('none');
      let obj = { email: state.email, source: 'email' }
			let response = await fetchData( obj, "user/get.php");
	  	if (response.status === "fail") 
				throw response.message;
      if(parseInt(response.is_user)>1){
        setState(prev => ({
        ...prev, 
        authError: {code: 'auth/email-already-exists'} }))
        return;
      }
    } catch (error) {
      console.log(error);
      setState(prev => ({
        ...prev, 
        authError: error }))
		}
    const data = await firebase.auth().createUserWithEmailAndPassword(state.email, state.password);
    //console.log(authUser);
    data.user.sendEmailVerification();
    const token = await data.user.getIdToken();
    constCreateDBUser({uid: data.user.uid, email: state.email, name: state.name, token: token, source: 'email'})
    await fetchData({ email: state.email }, 'terms-consent.php');
  }

  const signUser = async (e) => {
    e.preventDefault();
		if (!state.validator.allValid()) {
			state.validator.showMessages();
			setState({...state, "error": !state.validator.allValid()});
			return "";
		}
		try {
      //await firebase.setPersistence('none')
      const data = await firebase.auth().signInWithEmailAndPassword(state.email, state.password);
      const token = await data.user.getIdToken();
      if(!data.user.emailVerified){
        setState(prev => ({
          ...prev,
          emailVerified: false
        }));
        return;
      }
      const obj = { email: state.email, token: token, source: 'email' }
			let response = await fetchData( obj, "user/get.php");
	  	if (response.status === "fail") 
				throw response.message;
      setToken(token);
      setType('email');
      setUser(state.email, response.name);
      props.history.push('/products/');

		} catch (error) {
			console.log(error);
		  setState(prev => ({
        ...prev, 
        authError: error }))
		}
  }

  const resendConfirmation = () => {
    firebase.auth().sendEmailVerification();
  }

	const handleChange = (prop) => (event) => {
		setState({ ...state, [prop]: event.target.value });
	 };

	const setStateFromInput = (event) => {
		setState({...state, [event.target.name]: event.target.value});
	}

  const setStateFromCheckbox = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.checked;
		setState({...state, [event.target.name]: event.target.checked });
		if (!state.validator.fieldValid(event.target.name)) {
			state.validator.showMessages();
		}
	};

	const handleClickShowPassword = () => {
		setState({ ...state, showPassword: !state.showPassword });
	};
	
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	  };
  const opt = props.match.params.opt;
	return (
		<div className="container-fluid">
			<div className="form form-label-center row">
				<Box my={2.5} mx="auto" display="grid">
				<Helmet>
					<meta charSet="utf-8" />
					<title>{formatMessage({id: 'log_in'})}</title>
				</Helmet>
					
        {	opt === 'login' 
          ? <>
              <Typography align="center">Intra in cont cu </Typography>
              <Box py={1} display="grid">
                <Button 
                  variant="outlined"
                  margin="normal"
                  startIcon={
                    <Facebook style={{color: '#3b5998'}}/>
                    } 
                  onClick={() => providerSignIn('facebook')}>
                  Facebook
                </Button>
              </Box>
              <Box py={1} display="grid">
                <Button 
                  variant="outlined"
                  margin="normal"
                  startIcon={
                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                      <g fill="#000" fill-rule="evenodd">
                        <path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path>
                        <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path>
                        <path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path>
                        <path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path>
                        <path fill="none" d="M0 0h18v18H0z"></path>
                      </g>
                    </svg>
                    } 
                  onClick={() => providerSignIn('google')}>
                  Google
                </Button>
              </Box>
              <Divider/>
              <Typography align="center">Intra in cont cu email si parola</Typography>
              {/* <GoogleLogin 
                clientId={clientId}
                buttonText="Autentificare cu Google" 
                isSignedIn={true}
                onSuccess={onSuccess}
                onFailure={onFailure}
              /> */}
              <TextField 
                size='small'
                margin="normal"
                label={ formatMessage({id: "email"}) }
                name="email"
                variant="outlined" 
                helperText={ state.validator.message( formatMessage({id: "email"}), state.email, "required|email") }
                value={state.email} 	
                onChange={setStateFromInput}/>
              <FormControl margin="normal" /*className={clsx(classes.margin, classes.textField)}*/ variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{formatMessage({id: 'password'})}</InputLabel>
                <OutlinedInput
                  size='small'
                  id="outlined-adornment-password"
                  type={state.showPassword ? 'text' : 'password'}
                  value={state.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              { state.validator.message( "password", state.password, "required") }
              <Typography>{state.authError.code==='auth/wrong-password' ? 'Parola invalida' :''}</Typography>
              <Typography>{state.authError.code==='auth/too-many-requests' ? 'Prea multe incercari esuate. Accesul la cont a fost restrictionat temporar. Puteti astepta sau reseta parola pentru acces imediat.' :''}</Typography>
              <Typography>{state.emailVerified ? '' : 'Confirma mai intai emailul primit.'}</Typography>
              { state.emailVerified           
                ? ''
                : <Button onClick={resendConfirmation}>Trimite un alt email de confirmare</Button>
              }
              <Link to={'/user/forgot-password/'}>Parola uitata</Link>    
              <Box align="center" mb={1}>
                <Button margin="normal" className={classes.edit} onClick={signUser}>
                  Intra in cont
                </Button>
              </Box>  
            </>
          : ''
        }
				{ opt === "register" ?
          <>
            <TextField 
              size='small'
              margin="normal"
              label={ formatMessage({id: "email"}) }
              name="email"
              variant="outlined" 
              helperText={ state.validator.message( formatMessage({id: "email"}), state.email, "required|email") }
              value={state.email} 	
              onChange={setStateFromInput}/>
            <TextField 
              size='small'
              margin="normal"
              label={ formatMessage({id: "name"}) }
              name="name"
              variant="outlined" 
              value={state.name}
              helperText={ state.validator.message( formatMessage({id: "name"}), state.name, "required") }
              onChange={setStateFromInput}/>
            <FormControl margin="normal" /*className={clsx(classes.margin, classes.textField)}*/ variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">{formatMessage({id: 'password'})}</InputLabel>
              <OutlinedInput
                
                inputProps={{margin: "dense"}}
                id="outlined-adornment-password" size='small'
                type={state.showPassword ? 'text' : 'password'}
                value={state.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            { state.validator.message( "password", state.password, "required") } 
            <TextField 
                margin="normal"
                size='small'
                label={formatMessage({id: "confirm_password"})}
                name="confirmPassword"
                type={state.showPassword ? 'text' : 'password'}
                variant="outlined" 
                helperText={ 
                  state.validator.message(
                    formatMessage({id: "confirm_password"}), 
                    state.confirmPassword, 
                    `required|in:${state.password}`, 
                    {messages: {in: 'Parolele nu sunt identice!'}}) } 
                value={state.confirmPassword} 	
                onChange={setStateFromInput}/>
           <Typography>{['auth/email-already-exists', 'auth/email-already-in-use'].indexOf(state.authError.code) >= 0 ? 'Un cont cu aceasta adresa exista deja.' :''}</Typography>
          <Box>
            <Checkbox
              name="terms"
              value={state.terms}
              onChange={setStateFromCheckbox}
            />
            Accept&nbsp;
            <a href="/terms" target="_blank">
              Termenii si conditiile
            </a>
          </Box>
          {state.validator.message('Termenii', state.terms, 'accepted')}

            <Box align="center" mb={1}>
              <Button margin="normal" className={classes.edit} onClick={createUser}>
              { formatMessage({id: "register"}) }
              </Button>
            </Box>            
          </>
          : ''
        }       
				</Box>
			</div>
		</div>
	);

}


const mapStateToProps = (state) => ({
  //symbol: state.data.symbol,
  //email: state.account.email,
  cart: state.cartList.cart,
  wishlist: state.wishlist.list,
  token: state.account.token,
  auth: state.account.auth
});

export default compose(
	withFirebase,
	withRouter,
  connect(mapStateToProps)
)(injectIntl(Form));
