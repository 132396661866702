import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import CartPage from './cart-header'
import {removeFromCart} from '../../../actions'
import {getCartTotal} from '../../../services'
import Price from '../../../services/price';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& li': {
      '& a': {
        padding: '0 !important'
      },
      '& .MuiTypography-root': {
        fontSize: 12,
        '&.title span': {
          fontSize: 14
        }
      },
      '& img + .MuiTypography-root': {
        flex: '1 1 50%',
        margin: 8,
        height: '4.5em',
        overflow: 'hidden'
      },
      '& .MuiDivider-root': {
        margin: '0 -8px'
      }
      
    }
  }
}))

const CartContainer = ({cartList, total, symbol, removeFromCart, intl}) => {
    const classes = styles();
    const { formatMessage } = intl;
    return (
        <ul className={"show-div shopping-cart "+classes.container} >
            { cartList.map((item,index) => (
                <CartPage key={index} item={item} total={total} symbol={symbol} removeFromCart={() => removeFromCart(item)}  />
            ))}
            {(cartList.length > 0) 
            ? <li align="right">
                  <Typography align="right" className="title">
                    <Typography component="span">
                      {formatMessage({id: 'subtotal'})}:
                    </Typography>
                    <Typography component="span">
                      <Price symbol={symbol} price={total}/>
                    </Typography>
                    </Typography>
                    <Link to={`/cart/`} ><Button variant="outlined" className="edit">{formatMessage({id: 'cart_details'})}</Button></Link>
              </li>
            : <li>
                <Typography className="title">
                {formatMessage({id: 'empty_cart'})}
                </Typography>
            </li>
                
            }
        </ul>
    )
}


function mapStateToProps(state) {
    return {
        cartList: state.cartList.cart,
        total: getCartTotal(state.cartList.cart),
        symbol: state.data.symbol,
    }
}

export default connect(mapStateToProps, {removeFromCart})(injectIntl(CartContainer));
