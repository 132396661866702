import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { fetchData } from './../../utils/fetch';
import { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useState } from 'react';
import Breadcrumb from './../common/breadcrumb'
import Sidebar from './account-sidebar'


const style = makeStyles(theme => ({
    parent: {
        background: '#e9ebee'
    },
    element: {
        flex: '1 1 auto',
        background: 'white',
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },
    profileSpan: {
      width: 100,
      display: 'inline-block'
    }

}));

const Dashboard = (props) => {
    const classes = style();


    const Profile = (props) => {
      const [state, setState] = useState({
        name: '',
        phone: '',
        email: ''
      });

      useEffect(()=>{
        getProfile();
      },[])
  
      const getProfile = async () => {
        // if (!state.validator.allValid()) {
        //   state.validator.showMessages();
        //   setState({...state, "error": !state.validator.allValid()});
        //   return "";
        // }
        try {
         // await firebase.setPersistence('none');
          const obj = { email: props.account.email, token: props.account.token }
          let response = await fetchData( obj, "user/get.php");
          if (response.status === "fail") 
            throw response.message;
          console.log(response);
          setState({
            ...state, 
            ...response
          })
          //props.history.push('/');
        //	await firebase.doSignInWithEmailAndPassword(state.email, state.password);
          //const token = await firebase.getUser().getIdToken()
          //location.search.includes("ref") ? history.goBack() : history.push('/menu');
          //setUser(values.email);
              //setToken(token);
        } catch (error) {
          console.log(error);
        //	this.setState({ error })
        }
      }

        return <Box className={classes.element}>
          <Typography component="h2">
            Profile data
          </Typography>
          <Typography>
            <Typography className={classes.profileSpan} component="span">
              Name:
            </Typography>
             <Typography component="span">
               {state.name}
            </Typography>
          </Typography>    
          <Typography>      
            <Typography className={classes.profileSpan}>
              Email:
            </Typography>
            <Typography component="span">
               {state.email}
            </Typography>
          </Typography>
          <Typography>
            <Typography className={classes.profileSpan}>
              Phone:
            </Typography>
            <Typography component="span">
               {state.phone}
            </Typography>
          </Typography>
          <Link to={`/user/profile/`}>
              view your profile
          </Link> 
            
        </Box>
}
    const Activity = (props) => {
      const [state, setState] = useState({
        ordersCount: 0,
        reviewsCount: 0
      });

      useEffect(()=>{
        getStats('orders');
        getStats('reviews');
      },[])
  
      const getStats = async (type) => {
        try {

          const obj = { email: props.account.email, token: props.account.token, [type]: true }
          let response = await fetchData( obj, "account/get.php");
          if (response.status === "fail") 
            throw response.message;
          console.log(response);
          setState(prev =>({
            ...prev, 
            ...response
          }))
        } catch (error) {
          console.log(error);
        }
      }  

      return (<Box className={classes.element} >        
          <Typography component="h2" align="center">
            My activity
          </Typography>
            <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography>
                  {`${state.ordersCount} ${state.ordersCount == 1 ? 'order' : 'orders'} made`}
                  </Typography>
                  <Link to={`/user/orders/`}>
                    see my orders
                  </Link>  
                </Box>
                <Box>
                  <Typography>
                  {'no. favorites'}
                  </Typography>
                  <Link to={`/wishlist`}>
                    see favorite products
                  </Link>  
                </Box>
                <Box>
                  <Typography>
                  {`${state.reviewsCount} ${state.reviewsCount == 1 ? 'reviews' : 'reviews'} added`}
                  </Typography>
                  <Link to={`/user/reviews`}>
                    see my reviews
                  </Link>   
                </Box>
            </Box>
            
        </Box>)}

    const Addresses = (props) => 
        <Box className={classes.element}>
            <Typography>
                My addresses
            </Typography>
            <Link to={`/user/profile#address-section`}>
                go to addresses
            </Link>  
            <Typography>
                prefered addres
            </Typography>
            <Typography>
                name
            </Typography>
            <Typography>
                phone
            </Typography>
            <Typography>
                adress
            </Typography>
        </Box>

    return (
      <Box className={`${classes.parent}`} >
        <Breadcrumb  title={'Account'}/>
        <Box display="flex">
          <Profile account={props.account}/>
          <Addresses account={props.account}/>
        </Box>
        <Activity account={props.account}/>
      </Box>     

    )
}

const mapStateToProps = (state) => ({
  account: state.account
})

export default compose(connect(mapStateToProps))(injectIntl(Dashboard))