import React, { Component, Fragment, useState } from "react";
//import {ReactTable} from 'react-table';
//import { ToastContainer, toast } from 'react-toastify';
import { compose } from "recompose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import {
  Box,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core/";
import Table from "./table.jsx";
import Select from "../../../utils/select.jsx";
import { getImagePath } from "../../../services";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiInputBase-root > .MuiOutlinedInput-input, .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: 4,
    },
  },
}));

const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(
    () => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    },
    [id, preFilteredRows]
  );

  // Render a multi-select box
  return (
    <Select
      default={{ value: "", text: "Toate" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      array={options}
    />
  );
};

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  //classes,
  saveInput, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onClick = (e) => {
    saveInput(index, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(
    () => {
      setValue(initialValue);
    },
    [initialValue]
  );

  return (
    <Box display="flex" alignItems="flex-end">
      <TextField
        /* className={classes.textField}*/
        variant="outlined"
        margin="none"
        multiline
        onChange={onChange}
        value={value}
      />
      {initialValue != value && <Check className="edit" onClick={onClick} />}
    </Box>
  );
};

export const Datatable = (props) => {
  const { edit, myData, remove, hideFilters, customFields } = props;
  const classes = useStyles();
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const columns = [];
  for (var key in myData[0]) {
    let column = {
      Header: <b>{capitalize(key.toString())}</b>,
      accessor: key,
      //sortType: (key =="id") ? 'basic' : 'alphanumeric',
      width: 10,
      filterable: true,
      style: { textAlign: "center" },
    };
    if (key === "text") {
      column.Cell = ({ cell }) => (
        <EditableCell
          {...cell}
          //classes={classes}
          saveInput={customFields.onClick}
        />
      );
    }
    /*if (key === "category") {
      column.Cell = ({ cell: { value } }) => (
          value.name
      )
    }*/
    if (key === "pictures") {
      column.Cell = ({ cell: { value } }) => (
        <img
          width="100px"
          alt=""
          style={{ objectFit: "cover" }}
          srcSet={
            value[0].sizes
              ? value[0].sizes
                  .split(",")
                  .map((res) => getImagePath(value[0], res))
                  .join(", ")
              : ""
          }
          sizes="(max-width: 768px) 300px, 100px"
          src={getImagePath(value[0], "")}
        />
      );
      column.filterable = false;
    }
    /*if(key === "id"){
          column.filterable = false;
        }
        if(key === "category") {
          column.Filter = SelectColumnFilter;
          column.filter = 'includes';
        }*/

    key !== "id" && key !== "display" && columns.push(column);
  }
  customFields &&
    columns.push({
      id: "toggle",
      disableSortBy: true,
      filterable: false,
      Header: <b>Afisare</b>,
      Cell: (row) => (
        <Switch
          checked={row.row.original.display == "1" ? true : false}
          onChange={(e) => customFields.handleChange(row.row.original.id)}
          name="status"
        />
      ),
    });
  !customFields &&
    columns.push({
      id: "move",
      accessor: (str) => "Move",
      Cell: (row) => (
        <>
          {/* <span onClick={() => {
              if (window.confirm('Are you sure you wish to delete this item?')) {
                remove(row.row.original);
              }
          }}>
            <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }} />
          </span> */}
          <Link
            to={`/${props.path}/edit/${
              row.row.original.id
            }/`} /*onClick={() => {
                if (window.confirm('Are you sure you wish to edit this item?')) {
                  return false
                }
            }}*/
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(40, 167, 69)",
              }}
            />
          </Link>
        </>
      ),
      style: { textAlign: "center" },
      disableSortBy: true,
    });
  return (
    <Table
      data={myData}
      hideFilters={hideFilters}
      classes={classes}
      columns={columns}
    />
  );
};
export default compose(
  withRouter,
  connect(null)
)(Datatable);
