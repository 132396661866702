import React, { Component, Fragment, useState, useCallback, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
//import { withFirebase } from "../../firebase";
//import Breadcrumb from "../breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { fetchMenu, fetchData } from "../../utils/fetch";
import { setToken, setUser, setLanguage, setName } from "../../actions";
//import Select from "../../utils/select";
import { Box, TextField, makeStyles, Typography } from '@material-ui/core';
import clsx from "clsx";
import { withFirebase } from '../firebase/context';
import { Button } from '@material-ui/core/';
import ChangePasswordForm from "./change-password";
import { Helmet } from 'react-helmet';
import { Divider } from '@material-ui/core';
import Formz from './shipping-form';
import AddressSection from './address-section';

const useStyles = makeStyles(theme => ({
  show: {
    display: 'inherit'
  },
  hide: {
    display: 'none'
  },
  form:{
    maxWidth: 800,
    flexWrap: 'wrap',
    '& .MuiTextField-root':{
      margin: theme.spacing(1),
      flex: [[1, 1, '45%']],
    },
  },
  element: {
      flex: '1 1 auto',
      background: 'white',
      margin: theme.spacing(2),
      padding: theme.spacing(2)
  },
  profileSpan: {
    width: 100,
    display: 'inline-block'
  },
  edit: {
    cursor: 'pointer',
    color: '#ffffff',
    margin: theme.spacing(0.25),
    backgroundColor: '#4caf50',
    '&:hover': {
      border: '1px solid #4caf50',
      color: '#4caf50',
    },
    '&.Mui-disabled': {
      color: 'inherit',
      backgroundColor: 'inherit'
    }
  },
  delete: {
    margin: theme.spacing(0.25),
    cursor: 'pointer',
    color: '#ffffff',
    backgroundColor: '#f44336',
    '&:hover': {
      border: '1px solid #f44336',
      color: '#f44336',
    },
  }

}))

const Form = (props) => {
	const [state, setState] = React.useState({
		validator: new SimpleReactValidator(),
		error: false,
    token: '',
    name: '',
    phone: '',
    email: '',
    password: '',
    showPassword: false,
    edit: false
  });

  const classes = useStyles();

  const disconnect = async (e) => {
    props.firebase.doSignOut().then( (e)=>console.log(e), (e)=>console.log(e) )
    setUser('');
    setToken('');
    props.history.push('/');
  } 

  useEffect(()=>{
    fetchUser();
  },[])

  const fetchUser = async () => {
    try {
      const obj = {
        email: props.email,
        uid: props.uid,
        token: props.token
      }
      let apiData = await fetchData( obj, "user/get.php");
      if (apiData.status === "fail") 
        throw apiData.message;
        //Cookie.set('token_valid_until', apiData.token_end_time);
      if(apiData.email === props.email) {
          setState(prev => ({...prev, ...apiData}));
          //setUser(apiData.email);
         // setToken(apiData.token);
          //props.history.push("/menu");
      }
      console.log(apiData);
    } catch (error) {
      console.log(error);
    }
  }

  const onCancel = () => setState({ ...state, edit: false });

  const onOpen = () => { setState(prev => ({ ...prev, edit: true })) };


  const onSave = async (obj) => {
    try {
      let response = await fetchData({...obj, email: props.email, token: props.token}, "user/edit.php");
      if (response.status === "fail") 
        throw response.message;
      console.log(response);
      const tempArr = [...state.addresses];
      const index = tempArr.findIndex(el => el.id === obj.id);
      tempArr[index] = obj;
      setState(prev => ({ ...prev, addresses: tempArr, edit: false}))
    } catch (error) {
      console.log(error);
    }
  }
  
  const {intl: {formatMessage}} = props;

	return ( <>
          <Box className={[classes.form,classes.element].join(' ')} p={1}>
            <Box>
            <Typography component="h2">
            Profile data
          </Typography>
          <Typography>
            <Typography className={classes.profileSpan} component="span">
              Name:
            </Typography>
             <Typography component="span">
               {state.name}
            </Typography>
          </Typography>    
          <Typography>      
            <Typography className={classes.profileSpan}>
              Email:
            </Typography>
            <Typography component="span">
               {state.email}
            </Typography>
          </Typography>
          <Typography>
            <Typography className={classes.profileSpan}>
              Phone:
            </Typography>
            <Typography component="span">
               {state.phone}
            </Typography>
          </Typography>
          </Box> 
            { state.email 
              ? <Formz 
                  onCancel={onCancel}
                  profile
                  onSave={onSave}
                  data={{name: state.name, email: state.email, phone: state.phone}}
                  className={{
                    container: state.edit ? classes.show : classes.hide,
                    form: classes.form,
                    edit: classes.edit,
                    delete: classes.delete  
                          }} />
              : '' }

            <Button 
                className={[classes.edit, !state.edit ? classes.show : classes.hide].join(' ')} 
                onClick={onOpen} 
                variant="outlined">Edit</Button>
            </Box>  
            <Box align="center">
            </Box>
            <Box align="center" pt={2}>
              <ChangePasswordForm classes={classes}/>
            </Box> 
            <Box align="center" pt={2}>
              <Button variant="outlined" className={classes.edit} onClick={disconnect} mt={2} mb={1}>
                  {formatMessage({id: 'log_out'})}
              </Button> 
            </Box>
          </>
	);
}


const mapStateToProps = (state) => ({
  email: state.account.email,
  token: state.account.token,
  uid: state.account.uid,
});

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps)
)(injectIntl(Form));
