import React, { Component, Fragment, useState, useCallback, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
//import { withFirebase } from "../../firebase";
//import Breadcrumb from "../breadcrumb";
//import Select from "../../utils/select";
import { Box, TextField, makeStyles, Typography } from '@material-ui/core';
import { withFirebase } from '../firebase/context';
import { Helmet } from 'react-helmet';
import ProfileSection from './profile-section';

const useStyles = makeStyles(theme => ({
  show: {
    display: 'inherit'
  },
  hide: {
    display: 'none'
  },
  parent: {
    background: '#e9ebee'
  },
  form:{
    maxWidth: 800,
    flexWrap: 'wrap',
    '& .MuiTextField-root':{
      margin: theme.spacing(1),
      flex: [[1, 1, '45%']],
    },
  },
  inputMargin: {

  },
  edit: {
    cursor: 'pointer',
    color: '#ffffff',
    margin: theme.spacing(0.25),
    backgroundColor: '#4caf50',
    '&:hover': {
      border: '1px solid #4caf50',
      color: '#4caf50',
    },
    '&.Mui-disabled': {
      color: 'inherit',
      backgroundColor: 'inherit'
    }
  },
  delete: {
    margin: theme.spacing(0.25),
    cursor: 'pointer',
    color: '#ffffff',
    backgroundColor: '#f44336',
    '&:hover': {
      border: '1px solid #f44336',
      color: '#f44336',
    },
  }

}))

const Form = (props) => {
	const [state, setState] = React.useState({});

  const classes = useStyles();

  const {intl: {formatMessage}} = props;

	return (
			<div className="container-fluid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Profile</title>
          </Helmet>
        <div className={"form form-label-center row "+classes.parent}>
          <Box mx="auto" className={classes.form}>
            <ProfileSection />
          </Box>
        </div>
			</div>
	);
}


const mapStateToProps = (state) => ({
  email: state.account.email,
  token: state.account.token,
  uid: state.account.uid,
});

export default compose(
  withFirebase,
  connect(mapStateToProps)
)(injectIntl(Form));
