import React, { Component, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFirebase } from './firebase';
import { injectIntl } from 'react-intl';
import { setToken } from '../actions';
import { Link, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

// Custom Components
import FooterOne from './common/footers/footer-one';
import NavBar from './common/navbar1';

// ThemeSettings
import ThemeSettings from './collection/common/theme-settings';
import Cookie from 'js-cookie';
import { uuid } from 'uuidv4';
import { Box } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { fetchData } from './../utils/fetch';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core/';
import { useState } from 'react';
import { Fragment } from 'react';

const LoadScript = (props) => {
	//let url = "";
	Cookie.get('cookie-consent') &&
		useEffect(
			() => {
				const script = document.createElement('script');
				script.src = props.url;
				script.async = true;
				document.body.appendChild(script);
				return () => {
					document.body.removeChild(script);
				};
			},
			[ props.url ]
		);
	return '';

	// rest of your component
};

const App = (props) => {
	const [ state, setState ] = useState({
		change: false
	});
	const cookie = Cookie.getJSON('_byroot-cookie-consent');

	useEffect(async () => {
		let token = props.token;
		token && console.log(jwt_decode(token));
		token && console.log(jwt_decode(token).exp - new Date().getTime() / 1000);
		await props.firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				user.getIdToken().then(function(idToken) {
					props.email && setToken(idToken);
					setState({ change: true });
				});
			} else {
				//token && alert('Not')
			}
		});
	}, []);

	const changeCookieStatus = async (status) => {
		const id = cookie && cookie.uuid ? cookie.uuid : uuid();
		const consent = { uuid: id, status: status };
		Cookie.set('_byroot-cookie-consent', consent, { expires: 5 * 365 });
		try {
			const response = await fetchData(consent, 'cookie-consent.php');
			setState({ change: true });
		} catch (error) {
			console.log(error);
		}
	};

	const onAccept = () => {
		changeCookieStatus('accepted');
	};

	const onDeny = () => {
		changeCookieStatus('denied');
	};

	return (
		<Fragment>
			{/* <LoadScript url="https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/7091674c0b9318cd33984355b12b3aad28db43ba01548d4ff012bcd0b043b67c.js"/>
    <LoadScript url="https://checkout.stripe.com/checkout.js"/>   */}
			<NavBar />
			<Box bgcolor="#e9ebee" pb="100px" minHeight="calc(100vh - 0px)">
				<Box
					className={props.history.location.pathname === '/' ? '' : 'container'}
					style={{ top: 105 }}
					position="relative"
				>
					{props.children}
				</Box>
			</Box>
			<ToastContainer position="top-right" autoClose={2000} limit={1} pauseOnHover={false} hideProgressBar />
			{/* <FooterOne /> */}
			{/* <Box 
        p={2}
        bottom={0}
        boxShadow={15}
        width="100%"
        bgcolor="white"
        position="fixed"
        display={
          !cookie || ( cookie && cookie.status == 'withdrawn') 
          ? "block"
          : "none" }>
          <Typography align="center">
            Acest website foloseste cookie-uri pentru a imbunatati experienta utilizatorilor. Vezi&nbsp;
            	<a href="/terms" target="_blank">Politica de Cookie-uri</a>
          </Typography>
          <Box align="center">
          <Button className="edit" onClick={onAccept}>Accept</Button>
          <Button className="delete" onClick={onDeny}>Refuz</Button>
          </Box>
      </Box> */}
			{/* <ThemeSettings /> */}
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	email: state.account.email,
	token: state.account.token
});

export default compose(withFirebase, withRouter, connect(mapStateToProps))(injectIntl(App));
