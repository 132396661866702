import React from 'react';
import { Box, Typography, TextField, Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { fetchData } from './../../utils/fetch';
import { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const initState = {
	originalFilename: '',
	forUpload: '',
	alt: ''
};
const Upload = (props) => {
	const [ state, setState ] = useState(initState);

	const uploadPicture = async () => {
		try {
			let fileName = state.originalFilename.replace(/\s/g, '-');
			const obj = {
				src: state.forUpload,
				alt: state.alt,
				token: props.token,
				email: props.email,
				filename: fileName.substring(0, fileName.lastIndexOf('.'))
			};
			let response = await fetchData(obj, 'image/new.php');
			if (response.status === 'fail') throw response.message;
			console.log(response);
			toast.success('imaginea a fost incarcata');
			props.onUploadPicture && props.onUploadPicture(response);
			setState(initState);
			document.querySelector('#file-input').value = '';
			/*const media = [ ...this.state.media ];
			const pictures = [ ...this.state.pictures ];
			pictures.push(response);
			media.unshift(response);
		
			this.setState((prev) => ({
				media: media,
				pictures: pictures
			}));*/

			/*this.setState({
        categories:  categories,
      })*/
		} catch (error) {
			toast.error('imaginea nu a putut fi incarcata');
			console.log(error);
		}
	};

	const setStateFromInput = (event) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const handleImgChange = (e) => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];
			//let pictures = [...state.forUpload];
			setState({
				...state,
				originalFilename: file.name
			});
			reader.onloadend = () => {
				/*pictures[i]*/
				//uploadPicture(reader.result);
				setState((prev) => ({
					...prev,
					forUpload: reader.result
				}));
			};
			reader.readAsDataURL(file);
		} catch (error) {}
	};

	return (
		<Box display={props.display == undefined || props.display ? 'block' : 'none'}>
			<Typography />
			<Box display="flex" width="fit-content" flexDirection="column" pt={1}>
				<input id="file-input" className="upload mx-auto" type="file" onChange={handleImgChange} />
				<TextField
					label="Descriere imagine"
					margin="normal"
					variant="outlined"
					size="small"
					labelWidth={70}
					name="alt"
					value={state.alt}
					onChange={setStateFromInput}
				/>
				<Button variant="outlined" disabled={!state.forUpload} onClick={uploadPicture}>
					Incarca
				</Button>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state) => ({
	token: state.account.token,
	email: state.account.email
});

export default compose(connect(mapStateToProps))(Upload);
