import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {priceAfterDiscount} from '../../../services';
import Price from '../../../services/price';
import { Box, Button, Divider } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Delete } from '@material-ui/icons'
import { injectIntl } from 'react-intl';
import { removeFromCart } from '../../../actions';

const styles = makeStyles(theme => ({
  element: {
    '& a': {
      padding: '0 !important'
    },
    '& .MuiTypography-root, .MuiButton-label': {
      fontSize: 12,
    },
    '& img + .MuiTypography-root': {
      flex: '1 1 50%',
      margin: 8,
      height: '4.5em',
      overflow: 'hidden'
    },
    '& .MuiDivider-root': {
      margin: '0 -8px'
    }
  }
}))

const CartHeader  = ({item, total, symbol, intl}) => {
  const {formatMessage} = intl;
  const classes = styles();
  return (
            <li className={classes.element}>
                <Box display="flex" alignItems="center">
                      <img width="55" alt="" pr={1} src={`${item.pictures[0].picture}`} />
                    <Typography><Link to={`${process.env.PUBLIC_URL}/product/${item.id}/`}>{item.name}</Link></Typography>
                    <Box align="right">
                      <Button 
                        endIcon={<Delete className="delete" fontSize="small" onClick={() => removeFromCart(item.id)}/>} >
                      </Button>
                      <Typography align="right">{item.qty} x <Price symbol={symbol} price={priceAfterDiscount(item.price,item.discount)}/></Typography>
                    </Box>
                </Box>
                <Divider/>
            </li>
        )
}



export default injectIntl(CartHeader);
