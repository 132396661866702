import React from "react";
import Datatable from "../common/datatable/datatable";
import { fetchData } from "../../utils/fetch";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core";

const Reviews = (props) => {
  const [state, setState] = useState({
    reviews: [],
  });

  useEffect(
    () => {
      getReviews();
    },
    [props.token]
  );

  const getReviews = async () => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
      };
      let response = await fetchData(obj, "review/get.php");
      if (response.status === "fail") throw response.message;

      console.log(response);
      //let parents = [...new Set(response.map(el => el.parent))];
      /* const reviews = response.map((el) => ({
        ...el,
        created: dayjs(el.created * 1000).format("DD MMM YYYY HH:mm"),
        shippingInfo: Object.entries(JSON.parse(el.shippingInfo))
          .map((el) => el[0] + ": " + el[1])
          .reduce((acc, el) => acc + "\n" + el, ""),
      }));*/
      //response.filter(el => parents.indexOf(el.id) === -1).map(el => ({...el, filters: JSON.parse(el.filters) }) )
      setState((prev) => ({
        ...prev,
        reviews: response.map((el) => ({
          ...el,
          text: el.text.replace(/\\n/g, "\n"),
          created: dayjs(el.created * 1000).format("DD MMM YYYY HH:mm"),
        })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const sendReview = async (review) => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
        id: review.id,
        display: review.display,
        text: review.text,
      };
      let response = await fetchData(obj, "review/edit.php");
      if (response.status === "fail") throw response.message;
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (id) => {
    const reviews = [...state.reviews];
    const index = reviews.findIndex((el) => el.id == id);
    reviews[index].display = 1 - 1 * reviews[index].display + "";
    sendReview(reviews[index]);
    setState((prev) => ({ ...prev, reviews: reviews }));
  };

  const onClick = (index, value) => {
    const reviews = [...state.reviews];
    reviews[index].text = value;
    sendReview(reviews[index]);
    setState((prev) => ({ ...prev, reviews: reviews }));
  };

  return (
    <>
      {/* <Link to={`/order/new/`}>Adauga o comanda noua</Link> */}
      <Datatable
        //edit={edit}
        //remove={remove}
        //path="order"

        customFields={{
          type: "Switch",
          handleChange: handleChange,
          onClick: onClick,
        }}
        myData={[...state.reviews]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Reviews);
