import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from '@material-ui/core/';
// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

const Crop = (props) => {
	const [ upImg, setUpImg ] = useState();

	const [ crop, setCrop ] = useState({
		unit: '%',
		width: 99,
		aspect: props.cropRatio.width / props.cropRatio.height
	});
	const [ completedCrop, setCompletedCrop ] = useState(null);

	const onLoad = useCallback((img) => {
		props.changeImgRef(img);
	}, []);

	useEffect(
		() => {
			setCrop({
				unit: '%',
				width: 100,
				aspect: props.cropRatio.width / props.cropRatio.height
			});
		},
		[ props.cropRatio ]
	);

	useEffect(
		() => {
			if (!completedCrop || !props.previewCanvasRef.current || !props.imgRef.current) {
				return;
			}

			const image = props.imgRef.current;
			image.crossOrigin = 'anonymous';
			const canvas = props.previewCanvasRef.current;
			const crop = completedCrop;

			image.onload = function() {
				const scaleX = image.naturalWidth / image.width;
				const scaleY = image.naturalHeight / image.height;
				//props.setImageSize(image.naturalWidth, image.naturalHeight)
				const ctx = canvas.getContext('2d');

				canvas.width = crop.width * scaleX * pixelRatio;
				canvas.height = crop.height * scaleY * pixelRatio;

				//ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
				ctx.imageSmoothingQuality = 'high';
				ctx.drawImage(
					image,
					crop.x * scaleX,
					crop.y * scaleY,
					crop.width * scaleX,
					crop.height * scaleY,
					0,
					0,
					crop.width * scaleX,
					crop.height * scaleY
				);
			};

			//props.onSave(previewCanvasRef.current.toDataURL())
		},
		[ completedCrop ]
	);

	// const onSave = () => {
	//   props.onSave(previewCanvasRef.current.toDataURL())
	// }

	return (
		<div>
			<ReactCrop
				//src={props.img}
				src={props.img}
				onImageLoaded={onLoad}
				crop={crop}
				onChange={(c) => setCrop(c)}
				onComplete={(c) => setCompletedCrop(c)}
			/>
			{/* <Button onClick={onSave}>Salveaza</Button> */}
		</div>
	);
};

export default Crop;
