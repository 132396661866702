import React from "react";
import Datatable from "../common/datatable/datatable";
import { fetchData } from "../../utils/fetch";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

const Categories = (props) => {
  const [state, setState] = useState({
    categories: [],
  });

  useEffect(
    () => {
      getCategoryfilters();
    },
    [props.token]
  );

  const getCategoryfilters = async () => {
    try {
      const obj = {
        imgSrc: true,
        token: props.token,
        email: props.email,
      };
      let response = await fetchData(obj, "product/category/get.php");
      if (response.status === "fail") throw response.message;
      console.log(response);
      //let parents = [...new Set(response.map(el => el.parent))];
      const categories = response.map((el) => ({
        id: el.id,
        name: el.name,
        final: el.final,
      }));
      //response.filter(el => parents.indexOf(el.id) === -1).map(el => ({...el, filters: JSON.parse(el.filters) }) )
      setState((prev) => ({
        ...prev,
        categories: categories,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Link to={`/category/new/`}>Adauga o categorie noua</Link>
      <Datatable
        //edit={edit}
        //remove={remove}
        path="category"
        myData={[...state.categories]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Categories);
