import React from 'react';
import Datatable from '../common/datatable/datatable';
import { fetchData } from './../../utils/fetch'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'recompose';
import { connect } from 'react-redux'

const Variations = props => {
  const [state, setState] = useState({
    variations: []
  })

  useEffect(()=>{
    fetchVariations();
  },[props.token])

  const fetchVariations = async () => {
    try {
      const obj = {       
        token: props.token,
        email: props.email, }
      let response = await fetchData( obj, "product/variation/get.php");
       if (response.status === "fail") 
         throw response.message;
      setState(prev => ({
        ...prev,
        variations: response
      }))
    } catch (error) {
      console.log(error);
    }
  }



  return (
  <>
    <Link to={`/variations/new/`}>Adauga o noua variatie</Link>
    <Datatable
      //edit={edit}
      //remove={remove}
      path="variations"
      myData={[...state.variations]}/>
  </>
  )
}

const mapStateToProps = state => ({
  token: state.account.token,
  email: state.account.email
})

export default 
  compose(
    connect(
      mapStateToProps,
      ))(Variations)

