import React, { Component, Fragment, useState, useCallback, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
//import { withFirebase } from "../../firebase";
//import Breadcrumb from "../breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { fetchMenu, fetchData } from "../../utils/fetch";
import { setToken, setUser, setLanguage, setName } from "../../actions";
//import Select from "../../utils/select";
import { Box, TextField, makeStyles, Typography } from '@material-ui/core';
import clsx from "clsx";
import { withFirebase } from '../firebase/context';
import { Button } from '@material-ui/core/';
import ChangePasswordForm from "./change-password";
import { Helmet } from 'react-helmet';
import { Divider } from '@material-ui/core';
import Formz from './shipping-form';

const useStyles = makeStyles(theme => ({
  show: {
    display: 'inherit'
  },
  hide: {
    display: 'none'
  },
  form:{
    maxWidth: 800,
    flexWrap: 'wrap',
    '& .MuiTextField-root':{
      margin: theme.spacing(1),
      flex: [[1, 1, '45%']],
    },
  },
  element: {
    flex: '1 1 auto',
    background: 'white',
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  inputMargin: {

  },
  edit: {
    cursor: 'pointer',
    color: '#ffffff',
    margin: theme.spacing(0.25),
    backgroundColor: '#4caf50',
    '&:hover': {
      border: '1px solid #4caf50',
      color: '#4caf50',
    },
    '&.Mui-disabled': {
      color: 'inherit',
      backgroundColor: 'inherit'
    }
  },
  delete: {
    margin: theme.spacing(0.25),
    cursor: 'pointer',
    color: '#ffffff',
    backgroundColor: '#f44336',
    '&:hover': {
      border: '1px solid #f44336',
      color: '#f44336',
    },
  }

}))

const Section = (props) => {
	const [state, setState] = React.useState({
		validator: new SimpleReactValidator(),
		error: false,
    token: '',
    name: '',
    phone: '',
    email: '',
    addresses: [],
    editIndex: -1
  });

  const classes = useStyles();


  useEffect(()=>{
    getAddresses();
  },[])


  const getAddresses = async () => {
    try {
      const obj = { email: props.email, token: props.token }
      let response = await fetchData( obj, "address/get.php");
      if (response.status === "fail") 
        throw response.message;
      console.log(response);
      setState(prev => ({ ...prev, addresses: response }))
    } catch (error) {
      console.log(error);
    }
  }

  const onCancel = () => setState({ ...state, editIndex: -1 });

  const onDelete = async (id) => {
    try {
      const obj = { id: id, email: props.email, token: props.token };
      let response = await fetchData(obj, "address/delete.php");
      if (response.status === "fail") throw response.message;
      console.log(response);
      const tempArr = [...state.addresses];
      const index = tempArr.findIndex(el => el.id === id);
      tempArr.splice(index, 1);
      setState(prev => ({ ...prev, addresses: tempArr, editIndex: -1}))
    } catch (error) {
      console.log(error);
    }
  }

  const onOpen = (i) => { setState(prev => ({ ...prev, editIndex: i })) };


  const onSave = async (obj) => {
    try {
      let response = await fetchData({...obj, email: props.email, token: props.token}, "address/edit.php");
      if (response.status === "fail") 
        throw response.message;
      console.log(response);
      const tempArr = [...state.addresses];
      const index = tempArr.findIndex(el => el.id === obj.id);
      tempArr[index] = obj;
      setState(prev => ({ ...prev, addresses: tempArr, editIndex: -1}))
    } catch (error) {
      console.log(error);
    }
  }

  const onCreate = async (obj) => {
    console.log(obj)
    try {
      let response = await fetchData({...obj, email: props.email, token: props.token}, "address/new.php");
      if (response.status === "fail") 
        throw response.message;
      console.log(response);
      const tempArr = [...state.addresses];
      tempArr.push(obj);
      setState(prev => ({ ...prev, addresses: tempArr, editIndex: -1}))
    } catch (error) {
      console.log(error);
    }
  }

  
  const {intl: {formatMessage}} = props;

	return (
            <Box className={classes.element} id="address-section">
              <Typography>Addresses</Typography>
              { state.addresses.map((el, i) => 
                <Box>
                  <Box p={1} display="flex" justifyContent="space-between">
                    <Box>
                      <Typography>
                        <Typography>
                          <b>
                            {el.name} - {el.phone}
                          </b>
                        </Typography>
                        <Typography>{[el.address,el.city,el.county].join(', ')}</Typography>
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Button 
                        className={[classes.delete, state.editIndex !== i ? classes.show : classes.hide].join(' ')} 
                        onClick={ ()=>onDelete(el.id)}
                        variant="outlined">Delete</Button>
                      <Button 
                        className={[classes.edit, state.editIndex !== i ? classes.show : classes.hide].join(' ')} 
                        onClick={ ()=>onOpen(i) } 
                        variant="outlined">Edit</Button>
                    </Box>
                  </Box>
                  { el 
                    ? <Formz 
                        address
                        onCancel={onCancel}
                        onSave={onSave}
                        data={el}
                        className={{
                          container: state.editIndex === i ? classes.show : classes.hide,
                          form: classes.form,
                          edit: classes.edit,
                          delete: classes.delete  
                          }} />
                    : '' }
                  <Divider />
                </Box>)

              }
              <Button className={classes.edit} onClick={() => onOpen(state.addresses.length)}>Add new address</Button>
              <Formz 
                    onCancel={onCancel}
                    address
                    onSave={onCreate}
                    data={undefined}
                    className={{
                      container: state.editIndex === state.addresses.length ? classes.show : classes.hide,
                      form: classes.form,
                      edit: classes.edit,
                      delete: classes.delete  
                      }} />
            </Box>
	);
}


const mapStateToProps = (state) => ({
  email: state.account.email,
  token: state.account.token,
  uid: state.account.uid,
});

export default compose(
  withFirebase,
  connect(mapStateToProps)
)(injectIntl(Section));
