import React, { Component, Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import CKEditors from "react-ckeditor-component";
import SimpleReactValidator from "simple-react-validator";
import {
  TextField,
  Box,
  Typography,
  Button,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormHelperText,
} from "@material-ui/core";
//import one from '../../../assets/images/pro3/1.jpg'
//import user from '../../../assets/images/user.png';
import { fetchData } from "../../utils/fetch";
import { showToast } from "../../services/index";
import { Autocomplete } from "@material-ui/lab";
import { InputLabel } from "@material-ui/core/";
import { Remove, Close, Label, Build, Add } from "@material-ui/icons";
import { compose } from "redux";
import { withStyles } from "@material-ui/styles";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getImagePath } from "../../services";
import MyDialog from "../products/dialog";
import { FormControl } from "@material-ui/core/";
import { Select } from "@material-ui/core/";
import { MenuItem } from "@material-ui/core/";
import { Prompt } from "react-router-dom";
const styles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
  textField: {
    "& .MuiInputLabel-root": {
      flex: "1 1 30%",
    },
    "& .MuiFormControl-root": {
      flex: "1 1 70%",
    },
  },
  trTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  list: {
    "& .MuiListItem-root": {
      padding: 4,
    },
    "& .MuiListItemIcon-root": {
      alignSelf: "center",
      justifyContent: "center",
    },
  },
  container: {
    "& .MuiAutocomplete-listbox": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .MuiAutocomplete-option": {
      flex: "0 0 80px",
    },
    "& .related-product .MuiSvgIcon-root": {
      position: "absolute",
      right: 4,
    },
    "& .MuiSelect-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& label": {
      margin: [[8, 0, 4, 0]],
    },
    "& input": {
      padding: 8,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: [[8, 65, 8, 8]],
      "& input.MuiAutocomplete-input": {
        padding: 0,
      },
    },
  },
}));

const MyTextField = (props) => (
  <TextField
    {...props}
    label={props.label}
    name={props.name}
    value={props.value}
    multiline={props.multiline}
    onChange={props.onChange}
    helperText={props.helperText}
    error={props.helperText}
    margin={props.margin || "none"}
    className={props.className}
    variant="outlined"
    size="small"
  />
);

const LeftLabelTextField = (props) => (
  <Box display="flex" className={props.className} alignItems="center">
    <InputLabel>{props.label}</InputLabel>
    <MyTextField {...props} className="" label="" />
  </Box>
);

const TopLabelTextField = (props) => (
  <Box ml={props.ml ? props.ml : 0} mr={props.mr ? props.mr : 0}>
    <InputLabel margin="none">{props.label}</InputLabel>
    <MyTextField {...props} label="" margin="none" />
  </Box>
);

const Form = (props) => {
  const classes = styles();
  const [state, setState] = useState({
    validator: new SimpleReactValidator(),
    edit: false,
    name: "",
    seoTitle: "",
    seoDescription: "",
    seoMobileDescription: "",
    keywords: '',
    isFinal: 0,
    picture: [],
    tempPicture: -1,
    media: [],
    tempProduct: -1,
    products: [],
    categories: [],
    category: "",
    parent: -1,
    filters: [],
    variant: false,
    unsaved: false,
  });

  useEffect(
    () => {
      let id = props.match.params.id;
      getCategoryfilters();
      //fetchMediaimage();
      fetchAllProducts();
      if (id) {
        // fetchSingleProduct(id);
        setState((prev) => ({
          ...prev,
          edit: true,
        }));
      }
    },
    [props.token]
  );

  useEffect(
    () => {
      console.log("effect");
    },
    [state.isFinal]
  );

  const getCategoryfilters = async () => {
    let id = props.match.params.id;
    try {
      const obj = {
        token: props.token,
        email: props.email,
        imgSrc: true,
      };
      let response = await fetchData(obj, "product/category/get.php");
      if (response.status === "fail") throw response.message;
      console.log(response);
      //let parents = [...new Set(response.map(el => el.parent))];
      const categories = response
        .filter((el) => el.final === "0")
        .map((el) => ({ ...el, filters: el.filters }));
      //response.filter(el => parents.indexOf(el.id) === -1).map(el => ({...el, filters: JSON.parse(el.filters) }) )
      const category = response.find((el) => el.id == id);
      setState((prev) => ({
        ...prev,
        categories: categories,
      }));
      category &&
        setState((prev) => ({
          ...prev,
          ...category,
          //picture: JSON.parse(category.picture),
          filters: JSON.parse(category.filters),
          isFinal: category.final,
          /*JSON.parse(category.filters).map((el) => ({
            ...el,
            list: Object.entries(el.list).map((el) => ({
              title: el[0],
              list: el[1],
            })),
          }))*/
        
          
        }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllProducts = async () => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
      };
      let products = await fetchData(obj, "product/get.php");
      if (products.status === "fail") throw products.message;
      setState((prev) => ({
        ...prev,
        products: products.map((el) => ({ id: el.id, category: el.category })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!state.validator.allValid()) {
      state.validator.showMessages();
      //forceUpdate();
      return "";
    }
    let endpoint = state.edit ? "edit" : "new";
    const obj = {
      id: props.match.params.id,
      /*seo_title: state.seoTitle,
      seo_description: state.seoDescription,*/
      token: props.token,
      email: props.email,
      name: state.name,
      seo_title: state.seoTitle,
      seo_description: state.seoDescription,
      seo_mobile_description: state.seoMobileDescription,
      keywords: state.keywords,
      //image: state.picture[0].id,
      parent: state.parent,
      final: state.isFinal,
      filters: JSON.stringify(state.filters)
      /*state.isFinal
        ? JSON.stringify(
            state.filters.map((el) => ({
              title: el.title,
              list: el.list.reduce(
                (acc, el) => ({ ...acc, [el.title]: el.list }),
                {}
              ),
            }))
          )
        : "[]",*/
    };
    try {
      const response = await fetchData(
        obj,
        "product/category/" + endpoint + ".php"
      );
      console.log(response);
      setState((prev) => ({ ...prev, unsaved: false }));
      showToast("Categoria a fost salvata", "success");
    } catch (error) {
      showToast("Eroare", "error");
    }
  };

  const setStateFromInput = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      unsaved: true,
    });
  };

  const onChangeParentOption = (e, value) => {
    if (value == null)
      setState((prev) => ({
        ...prev,
        parent: -1,
        unsaved: true,
      }));
    else
      setState((prev) => ({
        ...prev,
        parent: value.id,
        unsaved: true,
      }));
  };

  const addSpecificationCategory = () => {
    setState((prev) => ({
      ...prev,
      filters: [...state.filters, { t: "", l: [] }],
    }));
  };

  const addSpecificationValue = (e, i) => {
    let filters = [...state.filters];
    filters[i].l = [...filters[i].l, ""]
    setState((prev) => ({
      ...prev,
      filters: filters,
    }));
  };

  const removeSpecification = (i) => {
    //categorie titlu
    let filters = [...state.filters];
    filters.splice(i, 1)
    setState((prev) => ({
      ...prev,
      filters: filters,
      unsaved: true,
    }));
  };

  const removeValue = (i, j) => {
    let filters = [...state.filters];
    filters[i].l.splice(j, 1)
    setState((prev) => ({
      ...prev,
      filters: filters,
      unsaved: true,
    }));
  }

  const setSpecification = (e, i) => {
   // categorie titlu
    let filters = [...state.filters];
    filters[i].t = e.target.value;
    setState((prev) => ({
      ...prev,
      filters: filters,
      unsaved: true,
    }));
  };

  
  const setValue = (e, i, j) => {
   // categorie titlu
    let filters = [...state.filters];
    filters[i].l[j] = e.target.value;
    setState((prev) => ({
      ...prev,
      filters: filters,
      unsaved: true,
    }));
  };

  const toggleVariant = (e) => {
    setState({
      ...state,
      isFinal: e.target.value,
      panelIndex:
        state.panelIndex +
        (state.panelIndex >= 2 ? (e.target.value ? 1 : -1) : 0),
      unsaved: true,
    });
  };

  const onChangePanel = (index) => {
    setState((prev) => ({ ...prev, panelIndex: index }));
  };

  const setMainPicture = (picture) => {
    setState((prev) => ({ ...prev, picture: picture, unsaved: true }));
  };

  const { panelIndex, picture, isFinal } = state;
  const productItems = isFinal ? [["Specificatii", <Build />]] : [];
  return (
    <Fragment>
      <Prompt
        when={state.unsaved}
        message="Ai modificari nesalvate, sigur vrei sa parasesti pagina?"
      />
      <Breadcrumb title="Categorie" list={[["/categories/", "Categorii"]]} />
      <div className={"container-fluid " + classes.container}>
        <Box display="flex">
          <Box flex="1 1 70%">
            <Box mx={1} mb={2} display="grid">
              <MyTextField
                className={
                  classes.input //label="Nume produs"
                }
                margin="none"
                helperText={state.validator.message(
                  "name",
                  state.name,
                  "required|string"
                )}
                error={state.validator.message(
                  "name",
                  state.name,
                  "required|string"
                )}
                name="name"
                value={state.name}
                onChange={setStateFromInput}
              />
            </Box>
            <Box
              mx={1}
              mb={2}
              p={1}
              bgcolor="white"
              display="flex"
              flexWrap="wrap"
              flexDirection="column"
            >
              <InputLabel component="h2">SEO</InputLabel>
              <TopLabelTextField
                className={classes.tTextField}
                label="Titlu"
                name="seoTitle"
                helperText={state.validator.message(
                  "Title",
                  state.seoTitle,
                  "string"
                )}
                error={state.validator.message(
                  "Title",
                  state.seoTitle,
                  "string"
                )}
                value={state.seoTitle}
                onChange={setStateFromInput}
              />
              <Typography style={{ background: (state.seoTitle.length < 50 ? 'yellow' : state.seoTitle.length <= 70 ? 'green' : 'red') }}>
                      Lungime titlu: {state.seoTitle.length}/70
                </Typography>
              <TopLabelTextField
                className={classes.tTextField}
                label="Descriere"
                name="seoDescription"
                multiline
                value={
                  state.seoDescription //helperText={ this.state.validator.message("Description", this.state.seoDescription, "required")}
                }
                onChange={setStateFromInput}
                helperText={state.validator.message(
                  "Descriere",
                  state.seoDescription,
                  "string"
                )}
                error={state.validator.message(
                  "Descriere",
                  state.seoDescription,
                  "string"
                )}
              />
               <Typography style={{ background: (state.seoDescription.length < 120 ? 'yellow' : state.seoDescription.length <= 146 ? 'green' : 'red')}}>
                      Lungime descriere desktop: {state.seoDescription.length}/146
              </Typography>
            <TopLabelTextField
                className={classes.tTextField}
                label="Descriere Mobile"
                name="seoMobileDescription"
                multiline
                value={
                  state.seoMobileDescription //helperText={ this.state.validator.message("Description", this.state.seoDescription, "required")}
                }
                onChange={setStateFromInput}
                helperText={state.validator.message(
                  "Descriere",
                  state.seoMobileDescription,
                  "string"
                )}
                error={state.validator.message(
                  "Descriere",
                  state.seoMobileDescription,
                  "string"
                )}
              />
               <Typography style={{ background: (state.seoMobileDescription.length < 120 ? 'yellow' : state.seoMobileDescription.length <= 146 ? 'green' : 'red')}}>
                      Lungime descriere mobile: {state.seoMobileDescription.length}/146
              </Typography>
                       <TopLabelTextField
                      className={classes.tTextField}
                      label="Keywords"
                      name="keywords"
                      helperText={state.validator.message(
                        "Keywords",
                        state.keywords,
                        "string"
                      )}
                      value={state.keywords}
                      onChange={setStateFromInput}
                    />
            </Box>
            <Box mx={1} mb={2} p={1} pb={0} bgcolor="white">
              <Box display="flex" pb={1} position="relative">
                <InputLabel>Categorie cu produse:&nbsp;</InputLabel>
                <FormControl variant="outlined">
                  <Select value={isFinal} onChange={toggleVariant}>
                    <MenuItem value={0}>Nu</MenuItem>
                    <MenuItem value={1}>Da</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mx={-1}>
                <Divider />
              </Box>
              <Box mx={-1} display="flex">
                <Box
                  flex="0 0 auto"
                  borderColor="rgba(0, 0, 0, 0.12)"
                  border={1}
                  bgcolor="#e9ebee"
                >
                  <List
                    disablePadding
                    className={classes.list}
                    component="nav"
                    aria-label="main mailbox folders"
                  >
                    {productItems.map((el, i) => (
                      <Fragment>
                        <ListItem
                          divider
                          onClick={() => onChangePanel(i)}
                          button
                          selected={i == state.panelIndex}
                          disableGutters
                        >
                          <ListItemIcon>{el[1]}</ListItemIcon>
                          <ListItemText primary={el[0]} />
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                </Box>
                <Divider orientation="vertical" flexItem />

                {(
                  <Box width="100%" p={1}>
                    {state.filters.length
                      ? state.filters.map((el, i) => (
                          <Box key={i} display="flex" flexDirection="column">
                            <Box display="flex" alignItems="center">
                              <LeftLabelTextField
                                className={[
                                  classes.tTextField,
                                  classes.textField,
                                ].join(" ")}
                                label={"Titlu"}
                                style={{ width: 200 }}
                              value={el.t}
                              name={el.t}
                                helperText={state.validator.message(
                                  "Titlu",
                                  state.filters[i].t,
                                  "required|min:3,string"
                                )}
                                error={state.validator.message(
                                  "Titlu",
                                  state.filters[i].t,
                                  "required|min:3,string"
                                )}
                                onChange={(e) => setSpecification(e, i)}
                              />
                              <Close
                                className="delete"
                                onClick={() => removeSpecification(i)}
                              />
                            </Box>
                            {el.l.map((_el, j) => (
                              <Box key={j}>
                                <Box display="flex" alignItems="center">
                                  <LeftLabelTextField
                                    className={[
                                      classes.tTextField,
                                      classes.textField,
                                    ].join(" ")}
                                    style={{
                                      marginLeft: 20,
                                      width: 200,
                                    }}
                                    helperText={state.validator.message(
                                      "Valoare",
                                      el.t+j,
                                      "string|min:1"
                                    )}
                                    error={state.validator.message(
                                      "Valoare",
                                      el.t+j,
                                      "string|min:1"
                                    )}
                                    label={"Valoare"}
                                    value={_el}
                                    name={el.t+j}
                                    onChange={(e) =>
                                      setValue(e, i, j)
                                    }
                                  />
                                  <Close
                                    className="delete"
                                    onClick={() =>
                                      removeValue(i,j)
                                    }
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Box style={{ width: 200 }}>
                              <Button
                                variant="outlined"
                                onClick={(e) => addSpecificationValue(e, i)}
                                startIcon={<Add />}
                              >
                                Valoare
                              </Button>
                            </Box>
                          </Box>
                        ))
                      : ""}
                    <Button
                      variant="outlined"
                      onClick={addSpecificationCategory}
                      startIcon={<Add />}
                    >
                      Categorie
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box flex="1 1 30%">
            <Box mx={1} mb={2}>
              <Button onClick={submitForm} variant="outlined">
                Salveaza
              </Button>
            </Box>
            <Box mx={1} mb={2} p={1} bgcolor="white">
              <InputLabel component="h2">Categorie parinte</InputLabel>
              {state.categories && (
                <Autocomplete
                  options={state.categories}
                  getOptionLabel={(option) => option.name}
                  openOnFocus
                  disableClearable
                  defaultValue={state.categories.find(
                    (el) => el.id == state.parent
                  )}
                  onChange={
                    onChangeParentOption //disablePortal
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={state.validator.message(
                        "Categorie parinte",
                        state.parent,
                        "required"
                      )}
                      variant="outlined"
                    />
                  )}
                />
              )}
            </Box>
            
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Form);
