import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import CKEditors from 'react-ckeditor-component';
import SimpleReactValidator from 'simple-react-validator';
import { TextField, Box, Typography, Button } from '@material-ui/core';
//import one from '../../../assets/images/pro3/1.jpg'
//import user from '../../../assets/images/user.png';
import { getImagePath } from '../../services/index';
import { compose } from 'redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Edit } from '@material-ui/icons/';
import { Dialog, DialogContent } from '@material-ui/core/';
import Form from './form';
import { fetchData } from './../../utils/fetch';
import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles((theme) => ({
	selected: {
		boxShadow: 'inset 0 0 2px 3px #fff, inset 0 0 0 7px #5b9dd9',
		outline: '2px solid transparent',
		outlineOffset: '-6px'
	}
}));

const Media = (props) => {
	const classes = style();
	const [ state, setState ] = useState({
		open: false,
		pictures: []
	});

	useEffect(
		() => {
			const pictures = [ ...state.pictures ];
			props.uploadedPicture &&
				props.uploadedPicture.id &&
				!pictures.find((el) => el.id == props.uploadedPicture.id) &&
				pictures.unshift(props.uploadedPicture);
			setState((prev) => ({ ...prev, pictures: pictures }));
		},
		[ props.uploadedPicture ]
	);

	const setStateFromInput = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		});
	};

	const toggleModal = (id) => {
		setState((prev) => ({
			...prev,
			open: !state.open,
			selectedImage: id ? id : -1
		}));
	};

	const fetchMediaPictures = async () => {
		try {
			const obj = {
				token: props.token,
				email: props.email
			};
			let pictures = await fetchData(obj, 'image/get.php');
			if (pictures.status === 'fail') throw pictures.message;
			console.log(pictures);
			setState((prev) => ({
				...prev,
				pictures: pictures
			}));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(
		() => {
			fetchMediaPictures();
		},
		[ props.token ]
	);

	const selectImage = (im) => {
		props.selected && props.selected.indexOf(im.id) >= 0 ? props.onSelect(im, 'delete') : props.onSelect(im, 'add');
	};

	return (
		<Box className="col-lg-12" display={props.display == undefined || props.display ? 'block' : 'none'}>
			{/* <Box display="flex" width="fit-content" flexDirection="column" pt={1}>
				{/* <input className="upload mx-auto" type="file" onChange={handleImgChange} />
				<Button onClick={(e) => handleSubmit(e.target.id)} /> */}
			{/* <TextField
          label="Denumire imagine"
          margin="normal" variant="outlined" size="small" labelWidth={70}
          name="filename" value={state.filename} onChange={setStateFromInput} />
        <TextField
          label="Descriere imagine"
          margin="normal" variant="outlined" size="small" labelWidth={70}
          name="description" value={state.description} onChange={setStateFromInput} /> 
			</Box> */}
			{/* 
			<Button variant="outlined" onClick={uploadPicture}>
				Incarca imaginea
			</Button> */}
			<Link to="/media/new/">Adauga image</Link>
			<Box display="flex" flexWrap="wrap">
				{state.pictures.map((el) => (
					<Box
						className={
							props.selected && props.selected.findIndex((p) => p == el.id) >= 0 ? classes.selected : ''
						}
						flex="0 0 150px"
						key={'pic' + el.id}
						position="relative"
						p={1}
					>
						{/* <Link 
          style={{position:"absolute", right: 0, background: 'white'}} 
          to={`/media/${el.id}/`}>
          <Edit/>
        </Link> */}
						{props.dialog ? (
							''
						) : (
							<Button style={{ position: 'absolute', right: 0, background: 'white' }}>
								<Edit onClick={() => toggleModal(el.id)} />
							</Button>
						)}
						<img
							width="150"
							height="150"
							alt=""
							onClick={() => (props.dialog ? selectImage(el) : '')}
							style={{ objectFit: 'cover' }}
							srcset={el.sizes ? el.sizes.split(',').map((res) => getImagePath(el, res)).join(', ') : ''}
							sizes="(max-width: 768px) 450px, 150px"
							src={getImagePath(el, '')}
						/>
					</Box>
				))}
			</Box>
			<Dialog
				fullWidth={true}
				maxWidth={'lg'}
				open={state.open}
				onClose={toggleModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<DialogContent>
					<Form onClose={toggleModal} image={state.pictures.find((el) => el.id == state.selectedImage)} />
				</DialogContent>
			</Dialog>
		</Box>
	);
};

const mapStateToProps = (state) => ({
	token: state.account.token,
	email: state.account.email
});

export default compose(connect(mapStateToProps))(Media);
