import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
//import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import { IntlProvider, addLocaleData } from "react-intl";
import "./index.scss";
//import './admin/index.scss';
import "./main.css";

// Import custom components
import store from "./store";

import Products from "./components/products/index";
import ProductForm from "./components/products/form";

import Categories from "./components/categories/index";
import CategoryForm from "./components/categories/form";

import Variations from "./components/variations/index";
import VariationForm from "./components/variations/form";

import MediaGallery from "./components/media/gallery";
import MediaForm from "./components/media/form";
import MediaUpload from "./components/media/upload";

import Orders from "./components/orders/index";
import OrderForm from "./components/orders/form";

import Reviews from "./components/reviews/index";

import Posts from "./components/posts/index";
import PostForm from "./components/posts/form";

import Recipes from "./components/recipes/index";
import RecipeForm from "./components/recipes/form";

import Pages from "./components/unique-pages/index";
import PageForm from "./components/unique-pages/form";
// AccountSidebar from './components/account/account-sidebar';

// Features
import Layout from "./components/app";

import Login from "./components/account/login";

import ForgotPassword from "./components/account/forgot-password";

import CookiePolicy from "./components/pages/cookie-policy";

import Dashboard from "./components/account/dashboard";
import Profile from "./components/account/profile";

import Firebase, { FirebaseContext } from "./components/firebase";
import messages from "./language.json";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      store.getState().account.token ? (
        <Component {...props} />
      ) : (
        <Redirect to={"/user/auth/login/"} />
      )
    }
  />
);

const Root = (props) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "rgb(241,241,241)",
      },
      /*secondary: {
        main: red[50],
      }*/
      text: {
        primary: "#000000",
        //secondary: 'rgb(255,255,255)',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 500,
        md: 600,
        lg: 900,
        xl: 1300,
      },
    },
    overrides: {
      MuiCheckbox: {
        root: {
          color: "#000000",
        },
      },
      MuiSwitch: {
        colorSecondary: {
          color: "red",
          "& + $track": {
            backgroundColor: "red",
          },
          "&$checked": {
            color: "green",
            "& + $track": {
              backgroundColor: "green",
            },
          },
        },
      },
      MuiSvgIcon: {
        root: {
          "&.edit": {
            cursor: "pointer",
            margin: 4,
            color: "#4caf50",
          },
          "&.delete": {
            margin: 4,
            cursor: "pointer",
            color: "#f44336",
          },
        },
      },
      MuiButton: {
        root: {
          margin: [[4, 0]],
          "&.edit": {
            cursor: "pointer",
            color: "#ffffff",
            backgroundColor: "#4caf50",
            "&:hover": {
              border: "1px solid #4caf50",
              backgroundColor: "white",
              color: "#4caf50",
            },
            "&.Mui-disabled": {
              color: "inherit",
              backgroundColor: "inherit",
            },
          },
          "&.delete": {
            cursor: "pointer",
            color: "#ffffff",
            backgroundColor: "#f44336",
            "&:hover": {
              border: "1px solid #f44336",
              backgroundColor: "white",
              color: "#f44336",
            },
          },
        },
      },
      MuiRadio: {
        colorSecondary: {
          "&$checked": {
            color: "#000000",
          },
        },
      },
      MuiFormLabel: {
        root: {
          //color: '#3463fa',
          "&$focused": {
            color: "#000000",
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });

  return (
    <Provider store={store}>
      <IntlProvider locale="ro" messages={messages["ro"]}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Switch>
                <Layout>

                  <AuthRoute exact path={`/products/`} component={Products} />
                  <AuthRoute path={`/product/new`} component={ProductForm} />
                  <AuthRoute
                    path={`/product/edit/:id`}
                    component={ProductForm}
                  />

                  <AuthRoute
                    exact
                    path={`/categories/`}
                    component={Categories}
                  />
                  <AuthRoute path={`/category/new`} component={CategoryForm} />
                  <AuthRoute
                    path={`/category/edit/:id`}
                    component={CategoryForm}
                  />

                  <AuthRoute
                    exact
                    path={`/variations/`}
                    component={Variations}
                  />
                  <AuthRoute
                    path={`/variations/new`}
                    component={VariationForm}
                  />
                  <AuthRoute
                    path={`/variations/edit/:id`}
                    component={VariationForm}
                  />

                  <AuthRoute
                    exact
                    path={`/media/new`}
                    component={MediaUpload}
                  />
                  <AuthRoute path={`/media/edit/:id`} component={MediaForm} />
                  <AuthRoute exact path={`/media/`} component={MediaGallery} />

                  <AuthRoute exact path={`/orders/`} component={Orders} />
                  <AuthRoute path={`/order/new`} component={OrderForm} />
                  <AuthRoute path={`/order/edit/:id`} component={OrderForm} />

                  <AuthRoute exact path={`/reviews/`} component={Reviews} />

                  <AuthRoute exact path={`/posts/`} component={Posts} />
                  <AuthRoute path={`/post/new`} component={PostForm} />
                  <AuthRoute path={`/post/edit/:id`} component={PostForm} />

                  <AuthRoute exact path={`/recipes/`} component={Recipes} />
                  <AuthRoute path={`/recipe/new`} component={RecipeForm} />
                  <AuthRoute path={`/recipe/edit/:id`} component={RecipeForm} />
                  
                  <AuthRoute exact path={`/pages/`} component={Pages} />
                  <AuthRoute path={`/page/new`} component={PageForm} />
                  <AuthRoute path={`/page/edit/:id`} component={PageForm} />
                  <Route
                    path={`/user/forgot-password`}
                    component={ForgotPassword}
                  />
                  <AuthRoute path={`/user/profile`} component={Profile} />
                  <Route path={`/user/auth/:opt`} component={Login} />

                  <AuthRoute path={`/pages/dashboard`} component={Dashboard} />
                  <Route path={`/cookie-policy`} component={CookiePolicy} />
                  <AuthRoute exact path={`/`} component={Products} />
                </Layout>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
});

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Root />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
