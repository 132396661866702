import React from "react";
import Datatable from "../common/datatable/datatable";
import { fetchData } from "./../../utils/fetch";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";

const Pages = (props) => {
  const [state, setState] = useState({
    pages: [],
  });
  

  useEffect(
    () => {
      const fetchData = async () => {
          const pages = await fetchPages();
          setState((prev) => ({...prev, pages: pages}))
        //posts.length
          /*setState((prev) => ({
            ...prev,
            categories: categories,
            products: products.map((el) => ({
              id: el.id,
              name: el.name,
              category: categories.find(_el => _el.id == el.category).name,
              pictures: JSON.parse(el.pictures) ? JSON.parse(el.pictures) : [{}],
              //variations: JSON.parse(el.variations),
            })),
          }));*/
      }
      fetchData();
  
    },
    
    [props.token]
  );


  const fetchPages = async () => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
      };
      let pages = await fetchData(obj, "page/get.php");
      if (pages.status === "fail") throw pages.message;
      return pages;
    } catch (error) {
      console.log(error);
      return []
    }
  };

  return (
    <>
      <Datatable
        //edit={edit}
        //remove={remove}
        path="page"
        myData={[...state.pages]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Pages);
