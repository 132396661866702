import React, {Component, useState }  from 'react';

const FormattedPrice = (props) => {
      let price = parseFloat(props.price).toFixed(2).split('.');
      let [before, after] = useState(false);
      props.symbol_position === 'before' ? before = true : after = true;
      return  <>
        {before && "lei"}{price[0]}
        <sup>
          {price[1]}
        </sup>
        &nbsp;
        {after && "lei"}
      </>;
    }

export default FormattedPrice;