import React, {Component} from 'react';
import { Link} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterOne extends Component {

    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render () {

  return (
    <footer className="footer-light">
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row footer-theme partition-f">
            <div className="col">
              <div className="footer-title">
                <h4>Shop Info</h4>
              </div>
              <div className="footer-contant">
                  <ul>
                    <li>
                        <Link to={'/'}>DELIVERY TIME</Link>
                    </li>
                    <li>
                        <Link to={'/'}>Delivery costs</Link>
                    </li>
                    <li>
                        <Link to={'/'}>RETURN POLICY</Link>
                    </li>
                  </ul>
              </div>
            </div>
              <div className="col">
                  <div className="sub-title">
                      <div className="footer-title">
                          <h4>my account</h4>
                      </div>
                      <div className="footer-contant">
                          <ul>
                              <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >womens</Link></li>
                              <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >clothing</Link></li>
                              <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >accessories</Link></li>
                              <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >featured</Link></li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="col">
                  <div className="sub-title">
                      <div className="footer-title">
                          <h4>why we choose</h4>
                      </div>
                      <div className="footer-contant">
                          <ul>
                              <li><a href="#">shipping & return</a></li>
                              <li><a href="#">secure shopping</a></li>
                              <li><a href="#">gallary</a></li>
                              <li><a href="#">affiliates</a></li>
                              <li><a href="#">contacts</a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
            </div>
        </section>
        <div className="sub-footer ">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <div className="footer-end">
                            <p><i className="fa fa-copyright" aria-hidden="true"></i> 2018-19 themeforest
                                powered by pixelstrap</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
        )
    }
}

export default FooterOne;