import React, { Fragment } from 'react';
import { Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AccountCircle } from '@material-ui/icons/';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { setToken, setUser } from '../../actions';
import { withFirebase } from './../firebase/context';
import { withRouter } from 'react-router-dom';
import { useGoogleLogout } from 'react-google-login';
import { setType } from './../../actions/index';

const clientId = '368342962904-7f81duudhflmigkhb2291bcnav7jultg.apps.googleusercontent.com';

const array = [
	[ 'Produse', 'products' ],
	[ 'Categorii', 'categories' ],
	[ 'Media', 'media' ]
	/*["dashboard", "user/dashboard"],
  ["profile", "user/profile"],
  ["my_orders","user/orders"],
  ["wishlist","wishlist"],
  ["my_reviews","user/reviews"],
  ["my_warranties","user/warranties"],
  ["my_returns","user/returns"],
  ["service","user/service"],*/
];

const styles = makeStyles((theme) => ({
	element: {
		flex: '0 1 auto',
		background: 'white',
		padding: 8,
		margin: 16,
		[theme.breakpoints.up(768)]: {
			alignSelf: 'flex-start'
		}
	}
}));

const Sidebar = (props) => {
	const { intl: { formatMessage } } = props;
	const classes = styles();

	const onLogoutSuccess = (res) => {
		setType('');
		setUser('');
		setToken('');
		props.history.push('/');
	};

	const onFailure = () => {
		//console.log('Handle failure cases');
	};

	const { signOut } = useGoogleLogout({
		clientId,
		onLogoutSuccess,
		onFailure
	});

	const disconnect = async (e) => {
		e.preventDefault();
		[ 'email', 'facebook' ].indexOf(props.account.auth) >= 0 &&
			props.firebase.auth().signOut().then(() => {
				setType('');
				setUser('');
				setToken('');
				props.history.push('/');
			});
		props.account.auth === 'google' && signOut();
	};

	return (
		<Fragment>
			{props.account.token ? (
				<List className={props.cart ? '' : classes.element} component="nav" aria-label="sidebar">
					{array.map((el) => (
						<ListItem component={Link} to={`/${el[1]}/`}>
							{formatMessage({ id: el[0] })}
						</ListItem>
					))}
					<Link className="MuiListItem-root MuiListItem-gutters" to="/" onClick={disconnect}>
						{formatMessage({ id: 'log_out' })}
					</Link>
				</List>
			) : (
				<Box className={props.cart ? '' : classes.element} align="center">
					<AccountCircle color="primary" />
					<Typography>{formatMessage({ id: 'not_logged' })}</Typography>
					<Box>
						<Link to="/user/auth/login/">
							<Button style={{ width: 'auto' }} fullWidth variant="outlined" className="account">
								{formatMessage({ id: 'log_in' })}
							</Button>
						</Link>
						<Link to="/user/auth/register/">
							<Button style={{ width: 'auto' }} fullWidth variant="outlined" className="account">
								{formatMessage({ id: 'register' })}
							</Button>
						</Link>
					</Box>
				</Box>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	account: state.account
});

export default compose(withRouter, withFirebase, connect(mapStateToProps))(injectIntl(Sidebar));
