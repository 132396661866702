import React, { Component, Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import CKEditors from "react-ckeditor-component";
import SimpleReactValidator from "simple-react-validator";
import {
  TextField,
  Box,
  Typography,
  Button,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormHelperText,
} from "@material-ui/core";
//import one from '../../../assets/images/pro3/1.jpg'
//import user from '../../../assets/images/user.png';
import { fetchData } from "../../utils/fetch";
import { showToast } from "../../services/index";
import { Autocomplete } from "@material-ui/lab";
import { InputLabel } from "@material-ui/core/";
import { Remove, Close, Label, Build, Add } from "@material-ui/icons";
import { compose } from "redux";
import { withStyles } from "@material-ui/styles";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getImagePath } from "../../services";
import MyDialog from "../products/dialog";
import { FormControl } from "@material-ui/core/";
import { Select } from "@material-ui/core/";
import { MenuItem } from "@material-ui/core/";
import { Prompt } from "react-router-dom";
const styles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
  textField: {
    "& .MuiInputLabel-root": {
      flex: "1 1 30%",
    },
    "& .MuiFormControl-root": {
      flex: "1 1 70%",
    },
  },
  trTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  list: {
    "& .MuiListItem-root": {
      padding: 4,
    },
    "& .MuiListItemIcon-root": {
      alignSelf: "center",
      justifyContent: "center",
    },
  },
  container: {
    "& .MuiAutocomplete-listbox": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .MuiAutocomplete-option": {
      flex: "0 0 80px",
    },
    "& .related-product .MuiSvgIcon-root": {
      position: "absolute",
      right: 4,
    },
    "& .MuiSelect-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& label": {
      margin: [[8, 0, 4, 0]],
    },
    "& input": {
      padding: 8,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: [[8, 65, 8, 8]],
      "& input.MuiAutocomplete-input": {
        padding: 0,
      },
    },
  },
}));

const MyTextField = (props) => (
  <TextField
    {...props}
    label={props.label}
    name={props.name}
    value={props.value}
    multiline={props.multiline}
    onChange={props.onChange}
    helperText={props.helperText}
    error={props.helperText}
    margin={props.margin || "none"}
    className={props.className}
    variant="outlined"
    size="small"
  />
);

const TopLabelTextField = (props) => (
  <Box ml={props.ml ? props.ml : 0} mr={props.mr ? props.mr : 0}>
    <InputLabel margin="none">{props.label}</InputLabel>
    <MyTextField {...props} label="" margin="none" />
  </Box>
);

const Form = (props) => {
  const classes = styles();
  const [state, setState] = useState({
    validator: new SimpleReactValidator(),
    edit: false,
    name: "",
    seoTitle: "",
    seoDescription: "",
    seoMobileDescription: "",
    keywords: "",
    content: "",
    picture: [],
    unsaved: false,
  });

  useEffect(
    () => {
      let id = props.match.params.id;
      if (id) {
        fetchPost(id);
        setState((prev) => ({
          ...prev,
          edit: true,
        }));
      }
    },
    [props.token]
  );

  const fetchPost = async () => {
    let id = props.match.params.id;
    try {
      const obj = {
        token: props.token,
        email: props.email,
        id: id
      };
      let response = await fetchData(obj, "post/get.php");
      if (response.status === "fail") throw response.message;
      setState((prev) => ({
        ...prev,
        slug: response.slug,
        picture: JSON.parse(response.pictures),
          keywords: response.keywords,
          name: response.name,
          seoTitle: response.seo_title,
          seoDescription : response.seo_description,
        seoMobileDescription : response.seo_mobile_description,
        content: JSON.parse(response.content)
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!state.validator.allValid()) {
      state.validator.showMessages();
      //forceUpdate();
      return "";
    }
    let endpoint = state.edit ? "edit" : "new";
    const obj = {
      id: props.match.params.id,
      token: props.token,
      email: props.email,
      name: state.name,
      image: state.picture[0].id,
     slug: state.slug || state.name.toLowerCase().replace(/\s/g,'-'),
      seo_title: state.seoTitle,
      seo_description: state.seoDescription,
        seo_mobile_description: state.seoMobileDescription,
      keywords: state.keywords,
      content: JSON.stringify(state.content)
    };
    try {
      const response = await fetchData(
        obj,
        "post/" + endpoint + ".php"
      );
      console.log(response);
      setState((prev) => ({ ...prev, unsaved: false }));
      showToast("Postarea a fost salvata", "success");
    } catch (error) {
      showToast("Eroare", "error");
    }
  };

  const setStateFromInput = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      unsaved: true,
    });
  };

 const onChange = (evt) => {
    let newContent = evt.editor.getData();
    setState(prev => ({ ...prev, content: newContent, unsaved: true }));
  };

  const onChangePanel = (index) => {
    setState((prev) => ({ ...prev, panelIndex: index }));
  };

  const setMainPicture = (picture) => {
    setState((prev) => ({ ...prev, picture: picture, unsaved: true }));
  };

  const { panelIndex, picture, isFinal } = state;
  const productItems = isFinal ? [["Specificatii", <Build />]] : [];
  return (
    <Fragment>
      <Prompt
        when={state.unsaved}
        message="Ai modificari nesalvate, sigur vrei sa parasesti pagina?"
      />
      <Breadcrumb title="Postari" list={[["/posts/", "Postari"]]} />
      <div className={"container-fluid " + classes.container}>
        <Box display="flex">
          <Box flex="1 1 70%">
            <Box mx={1} mb={2} display="grid">
              <MyTextField
                className={
                  classes.input //label="Nume produs"
                }
                margin="none"
                helperText={state.validator.message(
                  "name",
                  state.name,
                  "required|string"
                )}
                error={state.validator.message(
                  "name",
                  state.name,
                  "required|string"
                )}
                name="name"
                value={state.name}
                onChange={setStateFromInput}
              />
                      </Box>
                      <Box mx={1} mb={2}>
                <CKEditors
                  activeclassName="p10"
                  content={state.content}
                  events={{
                    change: onChange,
                  }}
                />
                <FormHelperText error>
                  {state.validator.message(
                    "Continut",
                    state.content,
                    "required"
                  )}
                </FormHelperText>
              </Box>
            <Box
              mx={1}
              mb={2}
              p={1}
              bgcolor="white"
              display="flex"
              flexWrap="wrap"
              flexDirection="column"
            >
              <InputLabel component="h2">SEO</InputLabel>
              <TopLabelTextField
                className={classes.tTextField}
                label="Slug"
                name="slug"
                helperText={state.validator.message(
                  "Slug",
                  state.slug,
                  "string"
                )}
                error={state.validator.message(
                  "Slug",
                  state.slug,
                  "string"
                )}
                value={state.slug}
                onChange={setStateFromInput}
              />    
              <TopLabelTextField
                className={classes.tTextField}
                label="Titlu"
                name="seoTitle"
                helperText={state.validator.message(
                  "Title",
                  state.seoTitle,
                  "string"
                )}
                error={state.validator.message(
                  "Title",
                  state.seoTitle,
                  "string"
                )}
                value={state.seoTitle}
                onChange={setStateFromInput}
              />
              <Typography style={{ background: (state.seoTitle.length < 50 ? 'yellow' : state.seoTitle.length <= 70 ? 'green' : 'red') }}>
                      Lungime titlu: {state.seoTitle.length}/70
                </Typography>
              <TopLabelTextField
                className={classes.tTextField}
                label="Descriere"
                name="seoDescription"
                multiline
                value={
                  state.seoDescription //helperText={ this.state.validator.message("Description", this.state.seoDescription, "required")}
                }
                onChange={setStateFromInput}
                helperText={state.validator.message(
                  "Descriere",
                  state.seoDescription,
                  "string"
                )}
                error={state.validator.message(
                  "Descriere",
                  state.seoDescription,
                  "string"
                )}
              />
               <Typography style={{ background: (state.seoDescription.length < 120 ? 'yellow' : state.seoDescription.length <= 146 ? 'green' : 'red')}}>
                      Lungime descriere desktop: {state.seoDescription.length}/146
              </Typography>
            <TopLabelTextField
                className={classes.tTextField}
                label="Descriere Mobile"
                name="seoMobileDescription"
                multiline
                value={
                  state.seoMobileDescription //helperText={ this.state.validator.message("Description", this.state.seoDescription, "required")}
                }
                onChange={setStateFromInput}
                helperText={state.validator.message(
                  "Descriere",
                  state.seoMobileDescription,
                  "string"
                )}
                error={state.validator.message(
                  "Descriere",
                  state.seoMobileDescription,
                  "string"
                )}
              />
               <Typography style={{ background: (state.seoMobileDescription.length < 120 ? 'yellow' : state.seoMobileDescription.length <= 146 ? 'green' : 'red')}}>
                      Lungime descriere mobile: {state.seoMobileDescription.length}/146
                          </Typography>
            <TopLabelTextField
                      className={classes.tTextField}
                      label="Keywords"
                      name="keywords"
                      helperText={state.validator.message(
                        "Keywords",
                        state.keywords,
                        "string"
                      )}
                      value={state.keywords}
                      onChange={setStateFromInput}
                    />
            </Box>
            <Box mx={1} mb={2} p={1} pb={0} bgcolor="white">
              <Box display="flex" pb={1} position="relative">
               
              </Box>
              <Box mx={-1}>
                <Divider />
              </Box>
              <Box mx={-1} display="flex">
                <Box
                  flex="0 0 auto"
                  borderColor="rgba(0, 0, 0, 0.12)"
                  border={1}
                  bgcolor="#e9ebee"
                >
                  <List
                    disablePadding
                    className={classes.list}
                    component="nav"
                    aria-label="main mailbox folders"
                  >
                    {productItems.map((el, i) => (
                      <Fragment>
                        <ListItem
                          divider
                          onClick={() => onChangePanel(i)}
                          button
                          selected={i == state.panelIndex}
                          disableGutters
                        >
                          <ListItemIcon>{el[1]}</ListItemIcon>
                          <ListItemText primary={el[0]} />
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                </Box>
                <Divider orientation="vertical" flexItem />
              </Box>
            </Box>
          </Box>
          <Box flex="1 1 30%">
            <Box mx={1} mb={2}>
              <Button onClick={submitForm} variant="outlined">
                Salveaza
              </Button>
            </Box>
                          <Box mx={1} mb={2} p={1} bgcolor="white">
                <InputLabel component="h2">Imagine produs</InputLabel>
                <Box>
                  {picture.length
                    ? picture.map((el) => (
                        <img
                          width="25%"
                          height="auto"
                          alt=""
                          key={"_pic" + el.id}
                          style={{
                            objectFit: "cover",
                            padding: 4,
                          }}
                          srcSet={
                            el.sizes
                              ? el.sizes
                                  .split(",")
                                  .map((res) => getImagePath(el, res))
                                  .join(", ")
                              : ""
                          }
                          sizes="(max-width: 768px) 300px, 100px"
                          src={getImagePath(el, "")}
                        />
                      ))
                    : ""}
                  <FormHelperText error>
                    {state.validator.message(
                      "Imagine principala",
                      picture,
                      "size:1,array|required"
                    )}
                  </FormHelperText>
                </Box>
                <MyDialog
                  pictures={state.picture}
                  setMainPicture={setMainPicture}
                />
              </Box>
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Form);
