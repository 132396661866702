import { useEffect } from "react";
import { fetchData } from "./../utils/fetch";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
let toastId = null;

export const showToast = (message, type) => {
  if (toastId && toast.isActive(toastId)) {
    //toast.dismiss();
    /*toast.update(
      toastId, {      
        type: type,
        render: message,
        transition: Zoom
      });*/
    toast.dismiss(toastId);
  }
  toastId = toast[type](message, {
    autoClose: 1500,
    pauseOnHover: false,
    hideProgressBar: true,
    transition: Zoom,
  });
};

export const useScript = (url) => {
  useEffect(
    () => {
      const script = document.createElement("script");

      script.src = url;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    },
    [url]
  );
};

export const getImagePath = (el, size) =>
  size
    ? [el.name, "-", size, "w.", el.type, " ", size, "w"].join("")
    : [el.name, el.type].join(".");

export const getAllProducts = async (obj) => {
  try {
    let response = await fetchData(obj, "product/get.php");
    return response;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getProductRecommendations = async (obj) => {
  try {
    let response = await fetchData(obj, "recommendations/get.php");
    return response;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getProduct = async (obj) => {
  try {
    let response = await fetchData(obj, "product/get.php");
    return response;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const priceAfterDiscount = (price, discount, tva) =>
  price * (1 - discount) * (1 + tva / 100);

// Get Minimum and Maximum Prices from Json Data
export const getMinMaxPrice = (products) => {
  let min = 10000,
    max = 0;

  products.map((product, index) => {
    let v = parseFloat(priceAfterDiscount(product.price, product.discount));
    min = v < min ? v : min;
    max = v > max ? v : max;
  });

  return { min: Math.floor(parseInt(min)), max: Math.ceil(parseInt(max)) };
};

export const getRelatedProducts = (products, productId) => {
  console.log(products);
  return products.filter((l) =>
    products.filter((el) => el.id == productId)[0].rec.includes(l.id)
  );
};

export const getCartItemsTotal = (cartItems) => {
  var total = 0;
  for (var i = 0; i < cartItems.length; i++) {
    total +=
      parseInt(cartItems[i].qty, 10) *
      parseFloat(
        priceAfterDiscount(cartItems[i].price, cartItems[i].discount),
        10
      );
  }
  return total;
};

export const getCartTotal = (cartItems, shipping_rate = 0) => {
  var total = getCartItemsTotal(cartItems) + parseFloat(shipping_rate);
  return total;
};
