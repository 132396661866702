import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme=>({
  item: {
    fontSize: 11
  }
}))

const Breadcrumb = props => {
  const {title, parent, list} = props;
  const classes = styles();
    return (
      <Box py={1}>
        <div>
          <div className="row">
          { <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb">                                   
                { list 
                ? list.map((el,i) => 
                    <li key={"bc"+i} className="breadcrumb-item">
                        <Link to={`${el[0]}`}>
                            <Typography component="span" className={classes.item}>
                              {el[1]}
                            </Typography>
                        </Link>
                    </li>
                  )
                : <>
                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                    { parent
                    ? <li className="breadcrumb-item" aria-current="page">
                        {parent}
                      </li>
                    : ''
                    }
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                  </>
                }
              </ol>
            </nav> 
          }
          </div>               
        </div>
      </Box>
    )
}

export default Breadcrumb;