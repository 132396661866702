import React from "react";
import Datatable from "../common/datatable/datatable";
import { fetchData } from "./../../utils/fetch";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";

const Recipes = (props) => {
  const [state, setState] = useState({
    recipes: [],
  });
  

  useEffect(
    () => {
      const fetchData = async () => {
          const recipes = await fetchRecipes();
          setState((prev) => ({...prev, recipes: recipes}))
        //recipes.length
          /*setState((prev) => ({
            ...prev,
            categories: categories,
            products: products.map((el) => ({
              id: el.id,
              name: el.name,
              category: categories.find(_el => _el.id == el.category).name,
              pictures: JSON.parse(el.pictures) ? JSON.parse(el.pictures) : [{}],
              //variations: JSON.parse(el.variations),
            })),
          }));*/
      }
      fetchData();
  
    },
    
    [props.token]
  );


  const fetchRecipes = async () => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
      };
      let recipes = await fetchData(obj, "recipe/get.php");
      if (recipes.status === "fail") throw recipes.message; 
      return recipes;
    } catch (error) {
      console.log(error);
      return []
    }
  };

  return (
    <>
      <Link to={`/recipe/new/`}>Adauga o noua reteta</Link>
      <Datatable
        //edit={edit}
        //remove={remove}
        path="recipe"
        myData={[...state.recipes]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({ 
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Recipes);
