import React, { Component, Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import CKEditors from "react-ckeditor-component";
import SimpleReactValidator from "simple-react-validator";
import isInt from "validator/es/lib/isInt";
import isFloat from "validator/es/lib/isFloat";
import {
  TextField,
  Box,
  Typography,
  Button,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
//import one from '../../../assets/images/pro3/1.jpg'
//import user from '../../../assets/images/user.png';
import { fetchData } from "../../utils/fetch";
import { priceAfterDiscount, showToast } from "../../services/index";
import { Autocomplete } from "@material-ui/lab";
import { InputLabel } from "@material-ui/core/";
import {
  Remove,
  Close,
  Label,
  Build,
  Add,
  LocalShipping,
  AttachFile,
  Receipt,
} from "@material-ui/icons";
import { compose } from "redux";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
const styles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
  textField: {
    "& .MuiInputLabel-root": {
      flex: "1 1 30%",
    },
    "& .MuiFormControl-root": {
      flex: "1 1 70%",
    },
  },
  trTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tTextField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  list: {
    "& .MuiListItem-root": {
      padding: 4,
    },
    "& .MuiListItemIcon-root": {
      alignSelf: "center",
      justifyContent: "center",
    },
  },
  container: {
    "& .MuiAutocomplete-listbox": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .MuiAutocomplete-option": {
      flex: "0 0 80px",
    },
    "& .related-product .MuiSvgIcon-root": {
      position: "absolute",
      right: 4,
    },
    "& .MuiSelect-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& label": {
      margin: [[8, 0, 4, 0]],
    },
    "& input": {
      padding: 8,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: [[8, 65, 8, 8]],
      "& input.MuiAutocomplete-input": {
        padding: 0,
      },
    },
  },
}));

const productItems = [
  ["Info livrare", <LocalShipping />],
  ["Info facturare", <Receipt />],
  ["Produse", <AttachFile />],
];

const MyTextField = (props) => (
  <TextField
    {...props}
    label={props.label}
    name={props.name}
    value={props.value}
    multiline={props.multiline}
    onChange={props.onChange}
    helperText={props.helperText}
    error={props.helperText}
    margin={props.margin || "none"}
    className={props.className}
    variant="outlined"
    size="small"
    //inputStyle={{ width: 125 }}
  />
);

const LeftLabelTextField = (props) => (
  <Box
    mb={props.mb ? 1 : 0}
    display="flex"
    className={props.className}
    alignItems="center"
  >
    <InputLabel>{props.label}</InputLabel>
    <MyTextField {...props} className="" label="" />
  </Box>
);

const TopLabelTextField = (props) => (
  <Box ml={props.ml ? 1 : 0} mr={props.mr ? 1 : 0}>
    <InputLabel margin="none">{props.label}</InputLabel>
    <MyTextField {...props} label="" margin="none" />
  </Box>
);

const Form = (props) => {
  const classes = styles();
  const [state, setState] = useState({
    validator: new SimpleReactValidator(),
    edit: false,
    items: [],
    products: [],
    tmpProduct: "",
    shipping_info: {},
    billing_info: {},
    panelIndex: 0,
    availablePaymentMethods: [],
    availableShippingMethods: [],
    paymentMethod: "",
    shippingMethod: "",
    unsaved: false,
  });

  useEffect(
    () => {
      let id = props.match.params.id;
      //getCategoryfilters();
      //fetchMediaimage();
      if (id) {
        fetchOrder(id);
        setState((prev) => ({
          ...prev,
          edit: true,
        }));
      }
      fetchAllProducts();
    },
    [props.token]
  );

  useEffect(
    () => {
      if (!state.products.length || !state.items.length) return;
      const products = state.products.filter(
        (el) => state.items.map((el) => el.name).indexOf(el.name) === -1
      );
      if (products.length !== state.products.length)
        setState((prev) => ({
          ...prev,
          products: products,
        }));
    },
    [state.products, state.items]
  );

  const fetchOrder = async (id) => {
    try {
      const obj = { token: props.token, email: props.email, order_id: id };
      const order = await fetchData(obj, "order/get.php");
      if (order.status === "fail") throw order.message;
      setState((prev) => ({
        ...prev,
        availablePaymentMethods: order.payment_method,
        paymentMethod: order.order.payment_type,
        availableShippingMethods: order.shipping_method,
        shippingMethod: order.shipping_method.find(
          (el) => el.name == order.order.shipping_method_name
        ),
        shipping_info: JSON.parse(order.order.shipping_info),
        billing_info: JSON.parse(order.order.billing_info),
        items: JSON.parse(order.order.items),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllProducts = async () => {
    try {
      const obj = {
        token: props.token,
        email: props.email,
      };
      let products = await fetchData(obj, "product/get.php");
      if (products.status === "fail") throw products.message;
      setState((prev) => ({
        ...prev,
        products: products
          .map((el) => ({
            ...el,
            price: el.variant ? "" : el.price,
            //variant: product.variant ? true : false,
            priceVariations: el.variant
              ? JSON.parse(el.price).flatMap((el) => Object.entries(el))
              : [],
          }))
          .flatMap((el) =>
            el.variant
              ? el.priceVariations.map((_el) => ({
                  id: el.id,
                  discount: el.discount,
                  qty: 1,
                  stock: el.stock,
                  tva: el.tva,
                  name: el.name + " - " + _el[0],
                  unitPrice: parseFloat(_el[1]),
                }))
              : {
                  id: el.id,
                  discount: el.discount,
                  qty: 1,
                  stock: el.stock,
                  name: el.name,
                  tva: el.tva,
                  unitPrice: el.price,
                }
          ),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!state.validator.allValid()) {
      state.validator.showMessages();
      //forceUpdate();
      return "";
    }
    let endpoint = state.edit ? "edit" : "new";
    const obj = {
      id: props.match.params.id,
      items: state.items,
      shipping_info: JSON.stringify(state.shipping_info),
      billing_info: JSON.stringify(state.billing_info),
      payment_type: state.paymentMethod,
      shipping_method_id: state.shippingMethod.id,
      shipping_method_rate: state.shippingMethod.rate,
      total: state.items.reduce(
        (acc, el) =>
          acc + priceAfterDiscount(el.unitPrice * el.qty, el.discount, el.tva),
        0
      ),
    };
    try {
      const response = await fetchData(obj, "order/" + endpoint + ".php");
      console.log(response);
      setState((prev) => ({ ...prev, unsaved: false }));
      showToast("Comanda a fost salvata", "success");
    } catch (error) {
      showToast("Eroare", "error");
    }
  };

  const setItemFromInput = (event, index) => {
    let items = [...state.items];
    items[index][event.target.name] = event.target.value;
    setState({ ...state, items: items, unsaved: true });
  };

  const setInfoFromInput = (event, type) => {
    setState({
      ...state,
      [type]: {
        ...state[type],
        [event.target.name]: event.target.value,
      },
      unsaved: true,
    });
  };

  const setTmpProduct = (v) => {
    setState({ ...state, tmpProduct: v });
  };

  const setPaymentMethod = (v) => {
    setState({ ...state, paymentMethod: v, unsaved: true });
  };

  const setShippingMethod = (v) => {
    setState({ ...state, shippingMethod: v, unsaved: true });
  };

  const addProduct = () => {
    let items = [...state.items];
    let products = [...state.products];
    items.push(state.tmpProduct);
    products.filter((el) => el.name != state.tmpProduct.name);
    setState((prev) => ({
      ...prev,
      items: items,
      products: products,
      tmpProduct: "",
      unsaved: true,
    }));
  };

  const removeProduct = (index) => {
    let items = [...state.items];
    let products = [...state.products];
    products.push(state.items[index]);
    items.splice(index, 1);
    setState((prev) => ({
      ...prev,
      items: items,
      products: products,
      unsaved: true,
    }));
  };

  const onChangePanel = (index) => {
    setState((prev) => ({ ...prev, panelIndex: index }));
  };

  const { panelIndex } = state;
  return (
    <Fragment>
      <Prompt
        when={state.unsaved}
        message="Ai modificari nesalvate, sigur vrei sa parasesti pagina?"
      />
      <Breadcrumb title="Comanda" list={[["/orders/", "Comenzi"]]} />
      <div
        className={
          "container-fluid " + classes.container + " " + classes.textField
        }
      >
        <Box display="flex">
          <Box flex="1 1 70%">
            <Box mx={1} mb={2} bgcolor="white">
              <Box mx={-1} display="flex">
                <Box
                  flex="0 0 auto"
                  borderColor="rgba(0, 0, 0, 0.12)"
                  border={1}
                  bgcolor="#e9ebee"
                >
                  <List
                    disablePadding
                    className={classes.list}
                    component="nav"
                    aria-label="main mailbox folders"
                  >
                    {productItems.map((el, i) => (
                      <Fragment>
                        <ListItem
                          divider
                          onClick={() => onChangePanel(i)}
                          button
                          selected={i == state.panelIndex}
                          disableGutters
                        >
                          <ListItemIcon>{el[1]}</ListItemIcon>
                          <ListItemText primary={el[0]} />
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                </Box>

                <Divider orientation="vertical" flexItem />
                <Box display={panelIndex == 0 ? "block" : "none"} p={1}>
                  <LeftLabelTextField
                    mb
                    label="Adresa"
                    value={state.shipping_info.address}
                    name="address"
                    onChange={(e) => setInfoFromInput(e, "shipping_info")}
                    helperText={state.validator.message(
                      "Adresa livrare",
                      state.shipping_info.address,
                      "min:5|required"
                    )}
                  />
                  <LeftLabelTextField
                    mb
                    /*helperText={state.validator.message(
                      "qty",
                      state.items[i].qty,
                      "numeric|min:1,num|max:" + state.items[i].stock + ",num"
                    )}*/ label="Localitate"
                    value={
                      state.shipping_info.city //onChange={(e) => setItemFromInput(e, i)}
                    }
                    name="city"
                    onChange={(e) => setInfoFromInput(e, "shipping_info")}
                    helperText={state.validator.message(
                      "Localitate livrare",
                      state.shipping_info.city,
                      "min:2|required"
                    )}
                  />
                  <LeftLabelTextField
                    mb
                    label="Judet"
                    value={state.shipping_info.county}
                    name="county"
                    onChange={(e) => setInfoFromInput(e, "shipping_info")}
                    helperText={state.validator.message(
                      "Judet livrare",
                      state.shipping_info.county,
                      "min:2|required"
                    )}
                  />
                  <LeftLabelTextField
                    mb
                    label="Nume"
                    value={state.shipping_info.name}
                    name="name"
                    onChange={(e) => setInfoFromInput(e, "shipping_info")}
                    helperText={state.validator.message(
                      "Nume contact",
                      state.shipping_info.name,
                      "min:5|required"
                    )}
                  />
                  <LeftLabelTextField
                    label="Telefon"
                    value={state.shipping_info.phone}
                    name="phone"
                    onChange={(e) => setInfoFromInput(e, "shipping_info")}
                    helperText={state.validator.message(
                      "Telefon contact",
                      state.shipping_info.phone,
                      "phone|required"
                    )}
                  />
                </Box>
                <Box display={panelIndex == 1 ? "block" : "none"} p={1}>
                  <LeftLabelTextField
                    mb
                    label="Adresa"
                    value={state.billing_info.address}
                    name="address"
                    onChange={(e) => setInfoFromInput(e, "billing_info")}
                    helperText={state.validator.message(
                      "Adresa facturare",
                      state.billing_info.address,
                      "min:5|required"
                    )}
                  />
                  <LeftLabelTextField
                    mb
                    /*helperText={state.validator.message(
                      "qty",
                      state.items[i].qty,
                      "numeric|min:1,num|max:" + state.items[i].stock + ",num"
                    )}*/ label="Localitate"
                    value={
                      state.billing_info.city //onChange={(e) => setItemFromInput(e, i)}
                    }
                    name="city"
                    onChange={(e) => setInfoFromInput(e, "billing_info")}
                    helperText={state.validator.message(
                      "Localitate facturare",
                      state.billing_info.city,
                      "min:2|required"
                    )}
                  />
                  <LeftLabelTextField
                    mb
                    label="Judet"
                    value={state.billing_info.county}
                    name="county"
                    onChange={(e) => setInfoFromInput(e, "billing_info")}
                    helperText={state.validator.message(
                      "Judet facturare",
                      state.billing_info.county,
                      "min:2|required"
                    )}
                  />
                  <LeftLabelTextField
                    mb
                    label="Nume"
                    value={state.billing_info.name}
                    name="name"
                    onChange={(e) => setInfoFromInput(e, "billing_info")}
                    helperText={state.validator.message(
                      "Nume factura",
                      state.billing_info.name,
                      "min:5|required"
                    )}
                  />
                  <LeftLabelTextField
                    label="Telefon"
                    value={state.billing_info.phone}
                    name="phone"
                    onChange={(e) => setInfoFromInput(e, "billing_info")}
                    helperText={state.validator.message(
                      "Telefon factura",
                      state.billing_info.phone,
                      "phone|required"
                    )}
                  />
                </Box>
                <Box display={panelIndex == 2 ? "block" : "none"} p={1}>
                  {state.items.length &&
                    state.items.map((el, i) => (
                      <>
                        <InputLabel>{el.name}</InputLabel>
                        <Box display="flex" alignItems="baseline">
                          <TopLabelTextField
                            mr
                            label="Qty"
                            value={el.qty}
                            name="qty"
                            onChange={(e) => setItemFromInput(e, i)}
                            helperText={state.validator.message(
                              "Qty",
                              el.qty,
                              "integer|min:1,num|max:" +
                                el.stock +
                                ",num|required"
                            )}
                          />
                          <TopLabelTextField
                            mr
                            label="Pret"
                            value={el.unitPrice}
                            name="unitPrice"
                            onChange={(e) => setItemFromInput(e, i)}
                            helperText={state.validator.message(
                              "Pret",
                              el.unitPrice,
                              "numeric|min:0,num|required"
                            )}
                          />
                          <TopLabelTextField
                            mr
                            label="Discount"
                            value={el.discount}
                            name="discount"
                            onChange={(e) => setItemFromInput(e, i)}
                            helperText={state.validator.message(
                              "Discount",
                              el.discount,
                              "numeric|min:0,num|max:1,num|required"
                            )}
                          />
                          <Close onClick={(e) => removeProduct(i)} />
                        </Box>
                        <Box display="flex">
                          <InputLabel>
                            Pret final unitar:{" "}
                            {priceAfterDiscount(
                              el.unitPrice,
                              el.discount,
                              el.tva
                            ).toFixed(2)}{" "}
                            Subtotal:{" "}
                            {priceAfterDiscount(
                              el.unitPrice * el.qty,
                              el.discount,
                              el.tva
                            ).toFixed(2)}
                          </InputLabel>
                        </Box>
                      </>
                    ))}
                  <Autocomplete
                    options={state.products}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(e, v) => setTmpProduct(v)}
                    openOnFocus
                    value={state.tmpProduct}
                    clearOnEscape
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                  <Box display="inline-block">
                    <Button
                      className={classes.tTextField}
                      disabled={!state.tmpProduct}
                      onClick={addProduct}
                    >
                      Adauga
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box flex="1 1 30%">
            <Box mx={1} mb={2}>
              <Button
                disabled={!state.unsaved}
                onClick={submitForm}
                variant="outlined"
              >
                Salveaza
              </Button>
            </Box>
            <Box mx={1} mb={2} p={1} bgcolor="white">
              <InputLabel>Metoda plata</InputLabel>
              <Autocomplete
                options={state.availablePaymentMethods}
                getOptionLabel={(option) => option}
                onChange={(e, v) => setPaymentMethod(v)}
                openOnFocus
                value={state.paymentMethod}
                disableClearable
                clearOnEscape
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Box>
            <Box mx={1} mb={2} p={1} bgcolor="white">
              <InputLabel>Metoda livrare</InputLabel>
              <Autocomplete
                options={state.availableShippingMethods}
                getOptionLabel={(option) => option.name || ""}
                onChange={(e, v) => setShippingMethod(v)}
                openOnFocus
                disableClearable
                value={state.shippingMethod}
                clearOnEscape
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  email: state.account.email,
});

export default compose(connect(mapStateToProps))(Form);
