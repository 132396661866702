import React from 'react';
import { TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator';
import { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { injectIntl } from 'react-intl';

const addressArr = [
  ["Name", "name", "required", 'flex60'],
  ["Phone", "phone", "required|phone", 'flex40'],
  ["City", "city", "required", 'flex60'],
  ["County", "county", "required", 'flex40'],
  ["Address", "address", "required", 'flex100']
]

const profileForm = [
  ["Name", "name", "required", 'flex100'],
  ["Phone", "phone", "required|phone", 'flex50'],
  ["Email", "email", "required|email", 'flex100']
];

const style = makeStyles(theme => ({
  // flex40: {
  //   flex: '1 1 30%',
  //   margin: theme.spacing(1)
  // },
  // flex50: {
  //   flex: '1 1 40%',
  //   margin: theme.spacing(1)
  // },
  // flex60: {
  //   flex: '1 1 40%',
  //   margin: theme.spacing(1)
  // },
  // flex100: {
  //   flex: '1 1 100%',
  //   margin: theme.spacing(1)
  // },
}))

const Form = (props) => {
  const classes = {...props.className, ...style()};
  const {onCancel, onSave, data, intl: {formatMessage}} = props;
  const generateArr = props.address ? addressArr : profileForm;
  const [state, setState] = data 
  ? useState({ ...data, validator: new SimpleReactValidator() })
  : useState({
    validator: new SimpleReactValidator(),
    ...generateArr.map(el => ({[el[1]]: ''}) )
  })

  useEffect(()=>{

    },[])

  const setStateFromInput = (event) => {
		setState({...state, [event.target.name]: event.target.value});
  }

  const beforeSave = () => {
    if (!state.validator.allValid()) {
			state.validator.showMessages();
			setState({...state, "error": !state.validator.allValid()});
			return "";
    }

    onSave({ 
      id: state.id,
      ...generateArr.reduce((acc, el) => 
        ({...acc, 
          [el[1]]: state[el[1]] 
        }),{} 
      )
    })

  }

    return <Box className={classes.container}>
      <Box display="flex" flexWrap="wrap" className={classes.form} p={1}>
        { generateArr.map(el  => 
          <Fragment key={"sf"+el[1]} >
            <TextField 
            label={el[0]}
            name={el[1]}
            className={classes[el[3]]}
            value={ state[el[1]] } 
            onChange={setStateFromInput}        
            variant="outlined" 
            size="small" />
            { state.validator.message(
                  el[0],
                  state[el[1]],
                  el[2]
            )}     
          </Fragment>
        )}
      </Box>
      <Box>
        <Button variant="outlined" onClick={onCancel} className="delete">{formatMessage({id: 'cancel'})}</Button>
        <Button variant="outlined" onClick={beforeSave} className="edit">{formatMessage({id: 'save'})}</Button>
      </Box>
    </Box>
    

}


export default injectIntl(Form);