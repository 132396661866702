import React from 'react';
import ReactCrop from 'react-image-crop';
import { useState } from 'react'
import { fetchData } from './../../utils/fetch'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';  
import { useEffect } from 'react'
import { Button, TextField } from '@material-ui/core'
import { Box } from '@material-ui/core/'
import { Link } from 'react-router-dom'
import { compose } from 'redux';
import { connect } from 'react-redux'
import { getImagePath } from '../../services/index';
import Crop from './crop';
import { useRef } from 'react';
import { Typography, FormLabel } from '@material-ui/core';
import { InputLabel } from '@material-ui/core/';

const pixelRatio = window.devicePixelRatio || 1;

const Form = props => {
  const imgRef = useRef(null);
  const cropCanvasRef = useRef(null);
  const scaleCanvasRef = useRef(null);
  const [state, setState] = useState({
    picture: {},
    cropRatio: {width: 16, height: 9},
    scaleSize: {width: 1, height: 0},
    originalSize: {width: 1, height: 0},
    filename: '',
    originalFilename: '',
    title: '',
    description: '',
    editedFile: '',

    
  })
  const setStateFromInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const changeCrop = (e) => {
    setState({
      ...state,
      cropRatio: {
        ...state.cropRatio,
        [e.target.name]: e.target.value
      }
    })
  }

    const changeScale = (e) => {
    setState({
      ...state,
      scaleSize: {
        width: 
          e.target.name=="width" 
          ? e.target.value*1
          : parseInt(e.target.value/(state.originalSize.height/state.originalSize.width)),
        height: 
          e.target.name=="height" 
          ? e.target.value*1
          : parseInt(e.target.value*(state.originalSize.height/state.originalSize.width))
      }
    })
  }


  const fetchMediaPictures = async () => {
    try {
      const obj = { 
        id: 'id',
        token: props.token,
        email: props.email,  
      }
      let response = await fetchData( obj, "image/get.php");
      if (response.status === "fail") 
         throw response.message;
      console.log(response);
      //let fileParts = response.filename.split('-');
      setState(prev => ({
        ...prev,
        alt: response.alt,
        type: response.type,
        //uuid: fileParts[fileParts.length-1],
        oldFilename: response.filename,
        filename: response.filename,
        name: response.name,
        //filename: response.filename.replace(/\..*/,'').replace(/-[^-.]*$/,''),
        description: response.alt
      }))
    } catch (error) {
      console.log(error);
    }
  }

  const editFile = (file) => {
    setState(prev => ({
      ...prev,
      editedFile: file
    }))
  }

  const onScale = () => {
    const image = imgRef.current;
    image.crossOrigin = "anonymous";
    const canvas = cropCanvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = state.scaleSize.width;
    canvas.height = state.scaleSize.height;

   // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    uploadPicture('scale', ctx.canvas.toDataURL())
  

  }

  const onCrop = () => {

    uploadPicture('crop', cropCanvasRef.current.toDataURL())
  }

  const uploadPicture = async (type, data) => {
    try {
      const obj = { 
        token: props.token,
        email: props.email,
        id: props.image.id, 
        alt: state.description, 
        [type]: true,     
        size: type=='scale' ? state.scaleSize : '',
        oldFilename: state.oldFilename,
        data: data,
        type: state.type,
        filename: state.filename.replaceAll(' ','-'),
      }
      //state.filename ? state.filename : state.originalFilename.replace(/\..*/,'') }
      let response = await fetchData( obj, "image/edit.php");
      if (response.status === "fail") 
        throw response.message;
      console.log(response);
      toast.success("imaginea a fost incarcata");
      props.onClose();
  
      /*this.setState({
        categories:  categories,
      })*/
    } catch (error) {
      toast.error("imaginea nu a putut fi incarcata");
      console.log(error);
    }
  }

  useEffect(()=>{
    let picture = {...props.image};
    let fileParts = picture.filename.split('-');
      setState(prev => ({
        ...prev,
        alt: picture.alt,
        type: picture.type,
        //uuid: fileParts[fileParts.length-1],
        oldFilename: picture.filename,//.replace('-'+fileParts[fileParts.length-1],''),
        filename: picture.filename,//.replace('-'+fileParts[fileParts.length-1],''),
        name: picture.name,
        //filename: response.filename.replace(/\..*/,'').replace(/-[^-.]*$/,''),
        description: picture.alt
      }))
    //fetchMediaPictures();
  },[props.token])

  const changeImgRef = (ref) => {
    imgRef.current = ref;
    setState(prev => ({
      ...prev,
      originalSize: {
          width: ref?.naturalWidth,
          height: ref?.naturalHeight
        },
        scaleSize: {
          width: ref?.naturalWidth,
          height: ref?.naturalHeight
        },
    }))
  }

  return <Box display="flex">
      <Box p={1} flex="1 1 auto">
        <Crop 
          previewCanvasRef={cropCanvasRef}
          imgRef={imgRef}
          changeImgRef={changeImgRef}
          onSave={editFile}
          cropRatio={state.cropRatio}
          img={getImagePath(state,'')}/>
        {/* <img 
          width="200" 
          height="200" 
          alt=""
          style={{objectFit: 'cover'}} 
          srcset={
            state.sizes 
            ? state.sizes.split(',').map(res => getImagePath(state,res) ).join(', ')
            : ''}
          sizes="(max-width: 768px) 450px, 150px"
          src={ getImagePath(state,'') } /> */}
      </Box>
      <Box display="flex" flex="0 0 200px" width="fit-content" flexDirection="column">
        <TextField
          label="Denumire imagine"
          margin="normal" variant="outlined" size="small" labelWidth={70}
          name="filename" value={state.filename} onChange={setStateFromInput} />
        <TextField
          label="Descriere imagine"
          margin="normal" variant="outlined" size="small" labelWidth={70}
          name="description" value={state.description} onChange={setStateFromInput} />
        <Box pt={1}>
          <Typography>Dimensiuni</Typography>
        </Box>
        <Typography className="MuiFormControl-marginDense">
          Dimensiuni originale: {state.originalSize.width}x{state.originalSize.height}
          </Typography>
        <Box display="flex">
          <TextField
          margin="dense" variant="outlined" size="small" labelWidth={30}
          name="width" value={state.scaleSize.width} onChange={changeScale} />
          <Box px={1}>
            <Typography className="MuiFormControl-marginDense" >x</Typography>
          </Box>
          <TextField
          margin="dense" variant="outlined" size="small" labelWidth={30}
          name="height" value={state.scaleSize.height} onChange={changeScale} />
        </Box>
        <Button variant="outlined" onClick={onScale}>Scaleaza</Button>
        <Box pt={1}>
          <Typography>Raport</Typography>
        </Box>
        <Box display="flex">
          <TextField
          margin="dense" variant="outlined" size="small" labelWidth={30}
          name="width" value={state.cropRatio.width} onChange={changeCrop} />
          <Box px={1}>
            <Typography className="MuiFormControl-marginDense" >:</Typography>
          </Box>
          <TextField
          margin="dense" variant="outlined" size="small" labelWidth={30}
          name="height" value={state.cropRatio.height} onChange={changeCrop} />
        </Box>
        <Button variant="outlined" onClick={onCrop}>Crop</Button>
      </Box>
      <canvas
          ref={cropCanvasRef}
          style={{
            display: 'none',
          }}
        />
      <canvas
          ref={scaleCanvasRef}
          style={{
            display: 'none',
          }}
        />
  </Box>
}

const mapStateToProps = state => ({
  token: state.account.token,
  email: state.account.email
})

export default compose(connect(mapStateToProps))(Form)